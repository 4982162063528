import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Nullable } from 'shared/hooks/createUseMergedFirebase'
import { GetPushKey } from 'shared/types/firebase'
import { Wifis } from 'shared/types/fleet'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Text, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { FormWifi, WifiEditor } from './WifiEditor'

type FormWifis = { wifis: FormWifi[] }

export type WifisDialogProps = {
  deferred: Deferred<Nullable<Wifis>>
  wifis: Wifis
  getPushKey: GetPushKey
}

export const WifisDialog = ({
  deferred,
  wifis,
  getPushKey,
}: WifisDialogProps) => {
  const formWifis = Object.entries(wifis)
    .map(([key, wifi]) => {
      return { key, wifi }
    })
    .sort((a, b) => {
      return a.wifi.priority - b.wifi.priority
    })

  const context = useForm<FormWifis>({
    defaultValues: { wifis: formWifis },
    mode: 'onBlur',
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = context

  const { fields, append, move, remove } = useFieldArray({
    name: 'wifis',
    control,
  })

  const handleClose = () => {
    if (!isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  function appendWifi() {
    const firebaseKey = getPushKey()

    append({
      key: firebaseKey,
      wifi: {
        ssid: '',
        password: '',
        isHidden: false,
        priority: 0,
      },
    })
  }

  return (
    <Dialog
      title={<Title>Éditer les réseaux Wifi</Title>}
      onClose={() => deferred.reject(DIALOG_CLOSED_REASON)}
    >
      <FormProvider {...context}>
        <form
          onSubmit={handleSubmit(async ({ wifis }) => {
            let priority = 0
            const editedWifis: Nullable<Wifis> = {}

            wifis.forEach((item) => {
              if (item.wifi) {
                item.wifi.priority = priority++
              }
              editedWifis[item.key] = item.wifi
            })

            return deferred.resolve(editedWifis)
          })}
        >
          <div className="flex flex-col gap-3">
            {fields.length === 0 && <Text>Aucun réseau Wifi configuré</Text>}

            {fields.map((field, index) => {
              if (field.wifi === null) return null
              else
                return (
                  <WifiEditor
                    key={field.id}
                    name={`wifis.${index}`}
                    wifisLength={fields.length}
                    remove={remove}
                    append={append}
                    move={move}
                    index={index}
                  />
                )
            })}

            <div className="flex flex-row items-center justify-end gap-3">
              <Button onClick={() => appendWifi()}>Ajouter un réseau</Button>
              <Button variant="outline" onClick={handleClose}>
                Annuler
              </Button>
              <SubmitButton isSubmitting={isSubmitting}>Valider</SubmitButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  )
}
