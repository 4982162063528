import { UseFormRegisterReturn } from 'react-hook-form'
import { Facilities } from 'shared/types/fleet'
import { Select } from './Select'

export const FacilitySelector = ({
  facilities,
  registration,
}: {
  facilities: Facilities
  registration: UseFormRegisterReturn
}) => {
  return (
    <Select {...registration}>
      {Object.entries(facilities)
        .filter(([_facilityId, facility]) => facility.type !== 'inactive')
        .sort(
          (
            [_facilityIdA, { displayName: displayNameA }],
            [_facilityIdB, { displayName: displayNameB }],
          ) => displayNameA.localeCompare(displayNameB),
        )
        .map(([facilityId, facility]) => (
          <option key={facilityId} value={facilityId}>
            {facility.displayName}
          </option>
        ))}
    </Select>
  )
}
