import { Category } from 'shared/types/alert'
import { Region, Regions, RoomRange, Zone, Zones } from 'shared/types/fleet'
import { HourMinute, TimeRange } from 'shared/types/timeRange'
import { FirebaseKey } from 'shared/types/utils'
import { EMPTY_REGION } from '../pages/explorer/ZoneEditor'
import { Edited } from './computeDiff'

export type FormZones = Record<FirebaseKey, FormZone>

export interface FormZone {
  name: string
  order: number
  regions: FormRegions
  isHoliday: boolean
  holidayZoneId?: FirebaseKey
}

type FormRegions = Record<FirebaseKey, FormRegion | null>

export interface FormRegion {
  roomRange: RoomRange
  timeRange: FormTimeRange
  category: Category | null
}

type FormTimeRange = {
  start: HourMinute | ''
  end: HourMinute | ''
}

export const toFormZones = (zones: Zones) => {
  const formZones: FormZones = {}

  Object.entries(zones).forEach(([zoneId, zone]) => {
    const regions: FormRegions = {}

    const formZone: FormZone = {
      name: zone.name,
      order: zone.order,
      regions,
      isHoliday: zone.isHoliday,
      holidayZoneId: zone.holidayZoneId ?? '',
    }

    if (!zone.regions) {
      const regionId = Math.random().toString(36).slice(2)
      formZone.regions[regionId] = EMPTY_REGION
    } else {
      Object.entries(zone.regions).forEach(([regionId, region]) => {
        formZone.regions[regionId] = {
          category: region.category ?? null,
          timeRange: {
            start: region.timeRange?.start ?? '',
            end: region.timeRange?.end ?? '',
          },
          roomRange: {
            from: region.roomRange?.from ?? '',
            to: region.roomRange?.to ?? '',
          },
        }
      })
    }

    formZones[zoneId] = formZone
  })

  return formZones
}

export const toEdited = (zones: FormZones) => {
  const editedZones: Edited<Zones> = {}

  Object.entries(zones).forEach(([zoneId, zone]) => {
    const regions: Edited<Regions> = {}

    Object.entries(zone.regions).forEach(([regionId, region]) => {
      if (region === null) regions[regionId] = null
      else
        regions[regionId] = {
          category: region.category,
          timeRange: isTimeRange(region.timeRange) ? region.timeRange : null,
          roomRange: isRoomRange(region.roomRange) ? region.roomRange : null,
        }
    })

    const editedZone: Edited<Zone> = {
      name: zone.name,
      order: zone.order,
      isHoliday: zone.isHoliday,
      holidayZoneId: zone.holidayZoneId || null,
      regions,
    }

    editedZones[zoneId] = editedZone
  })

  return editedZones
}

export function asRegion(formRegion: FormRegion) {
  const region: Region = {}
  if (formRegion.category) region.category = formRegion.category
  if (formRegion.roomRange && isRoomRange(formRegion.roomRange))
    region.roomRange = formRegion.roomRange
  if (formRegion.timeRange && isTimeRange(formRegion.timeRange))
    region.timeRange = formRegion.timeRange
  return region
}

export function asZone(formZone: FormZone) {
  const { name, order, regions, isHoliday, holidayZoneId } = formZone
  const zone: Zone = { name, order, regions: {}, isHoliday }

  if (holidayZoneId) {
    zone.holidayZoneId = holidayZoneId
  }

  Object.entries(regions).forEach(([regionKey, region]) => {
    if (region !== null) zone.regions[regionKey] = asRegion(region)
  })

  return zone
}

function isTimeRange(timeRange: FormTimeRange): timeRange is TimeRange {
  return timeRange.start !== '' && timeRange.end !== ''
}

function isRoomRange(roomRange: RoomRange): roomRange is RoomRange {
  return roomRange.from !== '' && roomRange.to !== ''
}
