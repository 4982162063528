import { Hint, Text } from './Text'

export const NetworkInfo = ({
  label,
  value,
}: {
  label: string
  value: string | undefined
}) => {
  return (
    <div className="flex items-baseline gap-1">
      <Text>{label}</Text>
      <Hint>{value}</Hint>
    </div>
  )
}
