export function compacted(number: string) {
  return number.replace(/ /g, '')
}

function isValidPhoneNumber(number: string) {
  const compactedNumber = compacted(number)
  return (
    compactedNumber.length === 0 || compactedNumber.match(/^0\d{9}$/) !== null
  )
}

export function phoneNumberValidator(number: string) {
  return isValidPhoneNumber(number) || 'Numéro de téléphone invalide'
}
