import { Phone, PhoneStatus } from 'common/types'
import React from 'react'
import { getPhoneStatusDisplay } from '../utils/phoneStatus'
import { Text } from './Text'

const phoneStatusIcon: Record<PhoneStatus, string> = {
  outOfOrder: '⚠️',
  spare: '📦',
  active: '',
}

export const PhoneListItem: React.FC<{
  phone: Phone
  isSelected?: boolean
}> = ({ phone, isSelected }) => {
  const { status, id } = phone

  return (
    <div
      className={`flex flex-row items-baseline justify-between gap-2 ${status === 'outOfOrder' || status === 'spare' ? 'opacity-50' : ''} ${isSelected ? 'bg-secondary text-secondary-foreground' : ''}`}
    >
      <Text>
        {'📱'}
        {phoneStatusIcon[status]} {getPhoneStatusDisplay(status)}
      </Text>
      <Text>{id}</Text>
    </div>
  )
}
