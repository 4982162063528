import { captureException } from '@sentry/react'
import { DeviceCreation } from 'common/types'
import { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { DEVICE_STATUS, getDeviceStatusDisplay } from 'shared/utils/device'
import { formCurrentDateTime } from 'shared/utils/time'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { addDevice } from '../../api'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { FacilitySelector } from '../../components/FacilitySelector'
import { Select } from '../../components/Select'
import { Error as Err, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { Textarea } from '../../components/ui/textarea'
import { getRoomValidator } from '../../utils/roomValidation'
import { registerSerialToSalt } from '../../utils/salt'
import {
  OrderSelector,
  computeOrdersAvailableStock,
} from '../orders/OrderSelector'

const STORAGE_KEY = 'lastAddDeviceValues'

export const AddDeviceDialog = ({
  deferred,
  serial,
}: {
  deferred: Deferred<void>
  serial: string
}) => {
  const { source, facilities, facilityDevices, orders, ordersUsage, salt } =
    useContext(DataContext)

  const ordersStock = useMemo(
    () => computeOrdersAvailableStock(orders, ordersUsage),
    [orders, ordersUsage],
  )

  const { handleSubmit, register, formState, setValue } =
    useForm<DeviceCreation>({
      defaultValues: {
        datetime: formCurrentDateTime(),
        orderId: '',
        serial,
        room: '',
        status: 'pending',
        comment: '',
        facilityId: '',
        partOrderIds: {
          SDCARD: '',
          POWER: '',
          CABLE: '',
          MICROPHONE: '',
          CASE: '',
        },
      },
    })

  useEffect(() => {
    const data = localStorage.getItem(STORAGE_KEY)
    if (data !== null) {
      try {
        const { facilityId, status, orderId, partOrderIds } = JSON.parse(data)
        setValue('facilityId', facilityId)
        setValue('status', status)
        setValue('orderId', orderId)
        setValue('partOrderIds', partOrderIds)
      } catch (error) {
        captureException(error, {
          extra: {
            data,
          },
        })
        console.info(
          `Could not parse and initialize from localStorage '${STORAGE_KEY}'`,
          data,
          error,
        )
        localStorage.removeItem(STORAGE_KEY)
      }
    }
  }, [setValue])

  const allSerials = useMemo(
    () =>
      Object.values(facilityDevices).flatMap((devices) => Object.keys(devices)),
    [facilityDevices],
  )

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog title={<Title>Ajouter un appareil</Title>} onClose={handleClose}>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async (values) => {
          localStorage.setItem(STORAGE_KEY, JSON.stringify(values))

          try {
            await registerSerialToSalt(salt, serial)
          } catch (error) {
            window.alert(
              `ARI-${serial}: ${
                error instanceof Error ? error.message : 'Erreur inconnue'
              }`,
            )
            return
          }

          values.room = values.room.trim()
          const ariID = await addDevice(values, source)

          window.alert(`ID du ARI : ${ariID}`)

          deferred.resolve()
        })}
      >
        <div className="flex flex-col gap-3 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Numéro de série</label>
            <Input
              readOnly
              {...register('serial', {
                required: 'Numéro de série requis',
                validate: (serial) =>
                  !allSerials.includes(serial) ||
                  'Numéro de série déjà utilisé',
              })}
            />
            {formState.errors.serial && (
              <Err>{formState.errors.serial.message}</Err>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label>Statut</label>
            <Select {...register('status')}>
              {DEVICE_STATUS.map((status) => (
                <option key={status} value={status}>
                  {getDeviceStatusDisplay(status)}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div className="flex flex-col gap-3 md:flex-row">
          <div className="flex flex-col gap-2">
            <label>Établissement</label>
            <FacilitySelector
              facilities={facilities}
              registration={register('facilityId', {
                required: 'Établissement requis',
              })}
            />
            {formState.errors.facilityId && (
              <Err>{formState.errors.facilityId.message}</Err>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label>Chambre</label>
            <Input
              {...register('room', {
                validate: getRoomValidator(facilityDevices, serial),
              })}
            />
            {formState.errors.room && (
              <Err>{formState.errors.room.message}</Err>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label>Commentaire</label>
          <Textarea {...register('comment')} />
        </div>
        <div className="flex flex-col gap-2">
          <label>Date</label>
          <Input type="datetime-local" {...register('datetime')} />
          {formState.errors.datetime && (
            <Err>{formState.errors.datetime.message}</Err>
          )}
        </div>
        <div className="flex flex-col gap-3 md:flex-row">
          <div className="flex flex-1 flex-col gap-2">
            <label>COMPUTER</label>
            <OrderSelector
              type="COMPUTER"
              registration={register('orderId', {
                required: 'COMPUTER requis',
              })}
              ordersStock={ordersStock}
            />
            {formState.errors.orderId && (
              <Err>{formState.errors.orderId.message}</Err>
            )}
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <label>CABLE</label>
            <OrderSelector
              type="CABLE"
              registration={register('partOrderIds.CABLE', {
                required: 'CABLE requis',
              })}
              ordersStock={ordersStock}
            />
            {formState.errors.partOrderIds?.CABLE && (
              <Err>{formState.errors.partOrderIds.CABLE.message}</Err>
            )}
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <label>CASE</label>
            <OrderSelector
              type="CASE"
              registration={register('partOrderIds.CASE', {
                required: 'CASE requis',
              })}
              ordersStock={ordersStock}
            />
            {formState.errors.partOrderIds?.CASE && (
              <Err>{formState.errors.partOrderIds.CASE.message}</Err>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3 md:flex-row">
          <div className="flex flex-1 flex-col gap-2">
            <label>MICROPHONE</label>
            <OrderSelector
              type="MICROPHONE"
              registration={register('partOrderIds.MICROPHONE', {
                required: 'MICROPHONE requis',
              })}
              ordersStock={ordersStock}
            />
            {formState.errors.partOrderIds?.MICROPHONE && (
              <Err>{formState.errors.partOrderIds.MICROPHONE.message}</Err>
            )}
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <label>POWER</label>
            <OrderSelector
              type="POWER"
              registration={register('partOrderIds.POWER', {
                required: 'POWER requis',
              })}
              ordersStock={ordersStock}
            />
            {formState.errors.partOrderIds?.POWER && (
              <Err>{formState.errors.partOrderIds.POWER.message}</Err>
            )}
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <label>SDCARD</label>
            <OrderSelector
              type="SDCARD"
              registration={register('partOrderIds.SDCARD', {
                required: 'SDCARD requis',
              })}
              ordersStock={ordersStock}
            />
            {formState.errors.partOrderIds?.SDCARD && (
              <Err>{formState.errors.partOrderIds.SDCARD.message}</Err>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
