import { NotUndefined } from 'shared/firebase/typedMethods'
import { isObjectEmpty } from 'shared/utils/defined'

export type SimpleType = string | number | boolean

type JSONValue = SimpleType | Array<JSONValue> | JSONObject<unknown>

type JSONObject<T> = {
  [key in keyof T]: JSONValue
}

export type JSONNullableValue =
  | SimpleType
  | Array<JSONValue> // no null supported in arrays
  | JSONNullableObject<unknown>
  | null

export type JSONNullableObject<T> = {
  [key in keyof T]?: JSONNullableValue
}

function isArray(value: unknown): value is Array<JSONValue> {
  return Array.isArray(value)
}

export function isObject<T>(value: unknown): value is JSONNullableObject<T> {
  return typeof value === 'object'
}

// Converts arrays to their actual object representation in firebase
function arrayAsObject<T>(array: Array<T>) {
  return array.reduce<Record<number, T>>((acc, value, index) => {
    acc[index] = value
    return acc
  }, {})
}

// All fields values must be nullable, even if they are not optional,
// in order to handle the Record<string, any> case where any value can be deleted
export type Edited<T extends object> = {
  [key in keyof T]?: NotUndefined<T[key]> extends JSONValue
    ? NotUndefined<T[key]> extends object
      ? Edited<NotUndefined<T[key]>> | null
      : T[key] | null
    : never
}

export function computeDiff<
  Base extends JSONObject<Base>,
  Delta extends Edited<Base>,
>(base: Base, update: Delta) {
  return Object.entries(update).reduce<JSONNullableObject<Base>>(
    (acc, [key_, value_]) => {
      const key = key_ as keyof Edited<Base>
      const value = value_ as JSONNullableValue
      const oldValue = base[key] // might be undefined

      if (value === null) {
        if (oldValue !== undefined) acc[key] = value
      } else if (isArray(value)) handleArray(value)
      else if (isObject(value)) handleObject(value)
      else if (oldValue !== value) acc[key] = value

      return acc

      function handleObject<T>(value: JSONNullableObject<T>) {
        const diff = computeDiff((oldValue || undefined) ?? {}, value)
        if (!isObjectEmpty(diff)) acc[key] = diff
      }

      function handleArray(value: Array<JSONValue>) {
        const oldArray = (oldValue as Array<JSONValue>) ?? []

        const diff = computeDiff(
          arrayAsObject(oldArray),
          arrayAsObject(value),
        ) as Partial<Record<number, JSONNullableValue>>

        // Pad with null for deleted entries
        for (let i = value.length; i < oldArray.length; i++) diff[i] = null

        if (!isObjectEmpty(diff)) acc[key] = diff
      }
    },
    {},
  ) as Edited<Base>
}
