import { DatabaseSchema } from 'common/databaseSchema'
import {
  getFacilityTypeIcon,
  isFacilityTypeCustomerOrExperimenter,
} from 'common/facilityType'
import { DeviceEssential } from 'common/types'
import {
  AlertTriangle,
  Check,
  Copy,
  Loader,
  Pencil,
  Square,
  SquareCheckBig,
  SquarePlus,
  Wifi,
} from 'lucide-react'
import { DateTime } from 'luxon'
import { toCanvas } from 'qrcode'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { MergedType, Nullable } from 'shared/hooks/createUseMergedFirebase'
import { Facility, TimeRanges, Wifis, Zones } from 'shared/types/fleet'
import { FacilityId, Serial } from 'shared/types/utils'
import { isDeviceRecording, isStatusOOO } from 'shared/utils/device'
import { plural } from 'shared/utils/plural'
import { ms } from 'shared/utils/time'
import { timeRangeString } from 'shared/utils/timeRange'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { editFacility } from '../../api'
import { DeviceEssentialItem } from '../../components/DeviceListItem'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { GrafanaPanel } from '../../components/GrafanaPanel'
import { Select } from '../../components/Select'
import { Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { get, getPushKey } from '../../firebaseMethods'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'
import { usePing } from '../../hooks/usePing'
import { Edited } from '../../utils/computeDiff'
import { createEnrollmentToken, syncPolicy } from '../../utils/mdm'
import { getSortedDeviceSerials } from '../../utils/sorters'
import { BatchEditDevicesDialog } from './BatchEditDevicesDialog'
import { registerVpnClients } from './DeviceInfos'
import { EditFacilityDialog } from './EditFacilityDialog'
import {
  FacilityTimeRangesDialog,
  FacilityTimeRangesDialogProps,
} from './TimeRangesDialog'
import { ViewContactsDialog } from './ViewContactsDialog'
import { WifisDialog, WifisDialogProps } from './WifisDialog'
import { ZonesDialog, ZonesDialogProps } from './ZonesDialog'
import { getMdmToken } from './mdm'

interface Props {
  facilityId: FacilityId
  facility: Facility
}

type OnlineFilterOptions = 'online' | 'offline' | ''

const ALL = ''

const FacilityInfos_: React.FC<Props> = ({ facilityId, facility }) => {
  const { pingStatuses, handlePing } = usePing()

  const [selectedSerials, setSelectedSerials] = useState<string[]>([])
  const [onlineFilter, setOnlineFilter] = useState<OnlineFilterOptions>(ALL)
  const [hideOOO, setHideOOO] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState<string>(ALL)

  const [editFacilityDialogData, setEditFacilityDialogData] = useState<{
    facility: Facility
    deferred: Deferred<void>
    action: (facility: Facility) => Promise<void>
  } | null>(null)

  const [batchEditDialogData, setBatchEditDialogData] = useState<{
    deferred: Deferred<void>
    serials: string[]
  } | null>(null)

  const [viewContactsDialogData, setViewContactsDialogData] = useState<{
    deferred: Deferred<void>
    contacts: string[]
  } | null>(null)

  const [wifisDialogData, setWifisDialogData] =
    useState<WifisDialogProps | null>(null)

  const [zonesDialogData, setZonesDialogData] =
    useState<ZonesDialogProps | null>(null)

  const [timeRangesDialogData, setTimeRangesDialogData] =
    useState<FacilityTimeRangesDialogProps | null>(null)

  const {
    displayName,
    name,
    address,
    zipCode,
    city,
    recordingTimeRange,
    analysisTimeRange,
    monitoringTimeRange,
    contacts = [],
    type,
    wifis = {},
    zones = {},
    comment,
    areSoundsDeleted,
  } = facility

  const { source, facilityDevices } = useContext(DataContext)

  const devices = facilityDevices[facilityId]

  const QRCanvasRef = useRef<HTMLCanvasElement>(null)

  const pingPathsMap = useMemo(
    () =>
      Object.keys(devices ?? {}).reduce<Record<string, string>>(
        (acc, serial) => {
          acc[serial] = `devicesPing/${serial}` as const
          return acc
        },
        {},
      ),
    [devices],
  )

  const versionPathsMap = useMemo(
    () =>
      Object.keys(devices ?? {}).reduce<Record<string, string>>(
        (acc, serial) => {
          acc[serial] = `devicesVersion/${serial}` as const
          return acc
        },
        {},
      ),
    [devices],
  )

  const { data: devicesPing } =
    useMergedFirebase<MergedType<'devicesPing/${string}', DatabaseSchema>>(
      pingPathsMap,
    )
  const { data: devicesVersion } =
    useMergedFirebase<MergedType<'devicesVersion/${string}', DatabaseSchema>>(
      versionPathsMap,
    )

  const hasActiveOrPendingDevices = useMemo(
    () =>
      Object.values(devices ?? {}).some((device) =>
        isDeviceRecording(device.status),
      ),
    [devices],
  )

  const sortedSerials = useMemo(
    () => getSortedDeviceSerials(devices || {}),
    [devices],
  )

  const now = Date.now()

  const isOnline = useCallback(
    (serial: Serial) => now - (devicesPing[serial] ?? 0) < ms(3.2, 'hours'),
    [devicesPing, now],
  )

  const devicesVersions = useMemo(
    () =>
      [
        ...new Set(
          Object.keys(devices ?? {}).map(
            (serial) => devicesVersion[serial] ?? '1',
          ),
        ),
      ].sort(),
    [devices, devicesVersion],
  )

  const filteredSerials = useMemo(
    () =>
      sortedSerials
        .filter((serial) =>
          onlineFilter === ALL
            ? true
            : onlineFilter === 'online'
              ? isOnline(serial)
              : !isOnline(serial),
        )
        .filter((serial) =>
          hideOOO
            ? !isStatusOOO((devices?.[serial] as DeviceEssential).status)
            : true,
        )
        .filter((serial) =>
          selectedVersion === ALL
            ? true
            : selectedVersion === '1'
              ? devicesVersion[serial] === null
              : devicesVersion[serial] === selectedVersion,
        ),
    [
      devices,
      devicesVersion,
      hideOOO,
      isOnline,
      onlineFilter,
      selectedVersion,
      sortedSerials,
    ],
  )

  const handleEditDetails = async (facility: Facility) => {
    const deferred = new Deferred<void>()

    setEditFacilityDialogData({
      facility,
      deferred,
      action: (values) =>
        editFacility(facilityId, facility, values, source, Date.now()),
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditFacilityDialogData(null)
    }
  }

  const handleToggleDevice = (serial: string) => {
    selectedSerials.includes(serial)
      ? setSelectedSerials(
          selectedSerials.filter((selectedSerial) => selectedSerial !== serial),
        )
      : setSelectedSerials([...selectedSerials, serial])
  }

  const handleViewContacts = async () => {
    const deferred = new Deferred<void>()

    setViewContactsDialogData({
      deferred,
      contacts,
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setViewContactsDialogData(null)
    }
  }

  const handleShowWifis = async () => {
    const deferred = new Deferred<Nullable<Wifis>>()

    const getFacilityWifiPushKey = () =>
      getPushKey(`facilities/${facilityId}/wifis`)

    setWifisDialogData({ deferred, wifis, getPushKey: getFacilityWifiPushKey })

    try {
      const wifis = await deferred.promise
      await editFacility(facilityId, facility, { wifis }, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setWifisDialogData(null)
    }
  }

  const handleShowZones = async () => {
    const deferred = new Deferred<Edited<Zones>>()

    const rooms = Object.values(devices ?? {}).map(({ room }) => room)

    const getFacilityZonePushKey = () =>
      getPushKey(`facilities/${facilityId}/zones`)

    setZonesDialogData({
      deferred,
      zones,
      rooms,
      getPushKey: getFacilityZonePushKey,
    })

    try {
      const zones = await deferred.promise
      await editFacility(facilityId, facility, { zones }, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setZonesDialogData(null)
    }
  }

  const handleEditTimeRanges = async () => {
    const deferred = new Deferred<TimeRanges>()

    const timeRanges = {
      recordingTimeRange,
      analysisTimeRange,
      monitoringTimeRange,
    }

    setTimeRangesDialogData({ deferred, timeRanges })

    try {
      const timeRanges = await deferred.promise

      await editFacility(facilityId, facility, timeRanges, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setTimeRangesDialogData(null)
    }
  }

  const handleBatchEditDevices = async () => {
    const deferred = new Deferred<void>()

    setBatchEditDialogData({
      deferred,
      serials: selectedSerials,
    })

    try {
      await deferred.promise
      setSelectedSerials([])
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setBatchEditDialogData(null)
    }
  }

  function handleSelectAllOrNoneDevices() {
    if (selectedSerials.length > 0) setSelectedSerials([])
    else setSelectedSerials(filteredSerials)
  }

  function handleCopyToClipboard() {
    const text = selectedSerials.map((serial) => `ARI-${serial}`).join(',')
    navigator.clipboard.writeText(text)
  }

  async function handleSyncPolicy() {
    const token = await getMdmToken()
    await syncPolicy(token, displayName, wifis).then(() => {
      alert('Synchronisation MDM réalisée')
    })
  }

  async function handleShowQRCode() {
    const token = await getMdmToken()
    const enrollmentToken = await createEnrollmentToken(token, displayName)

    const canvas = QRCanvasRef.current
    if (canvas) {
      canvas.style.display = 'block'
      toCanvas(canvas, enrollmentToken.qrCode)
    }
  }

  async function handleDownloadMacs() {
    type RawCsvDatum = [string, string, string, string]

    const headerRow: RawCsvDatum = ['Serial', 'id', 'Chambre', 'adresse MAC']

    const rows: RawCsvDatum[] = []

    await Promise.all(
      selectedSerials.map(async (serial) => {
        const macAddress = await get(`devices/${serial}/macAddress`)
        const device = devices[serial]
        rows.push([
          serial,
          device.id,
          device.room,
          macAddress ?? 'NO_MAC_ADDRESS',
        ])
      }),
    )

    const rawCsvData: RawCsvDatum[] = [headerRow, ...rows]

    const element = document.createElement('a')

    function toCsv<T extends RawCsvDatum>(data: T[]) {
      return data
        .map((datum: T) =>
          datum.map((value: unknown) => JSON.stringify(value)).join(','),
        )
        .join('\n')
    }

    const todayDate = DateTime.now().toISODate()
    const facilityName = facility.name
    const fileName = `adresses-MAC_${facilityName}_${todayDate}.csv`
    const fileContent = toCsv(rawCsvData)

    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent),
    )
    element.setAttribute('download', fileName)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const handleRegisterVpnClient = async () => {
    await registerVpnClients(selectedSerials)
  }

  return (
    <>
      {editFacilityDialogData && (
        <EditFacilityDialog {...editFacilityDialogData} />
      )}

      {batchEditDialogData && (
        <BatchEditDevicesDialog {...batchEditDialogData} />
      )}

      {viewContactsDialogData && (
        <ViewContactsDialog {...viewContactsDialogData} />
      )}

      {wifisDialogData && <WifisDialog {...wifisDialogData} />}

      {zonesDialogData && <ZonesDialog {...zonesDialogData} />}

      {timeRangesDialogData && (
        <FacilityTimeRangesDialog {...timeRangesDialogData} />
      )}

      <div className="flex flex-1 flex-col gap-3 p-4">
        <div className="flex flex-1 flex-col gap-6 overflow-auto">
          <div className="flex flex-col justify-between lg:flex-row">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-4">
                <Title>
                  {getFacilityTypeIcon(type)} {displayName}
                </Title>
                <Button onClick={() => handleEditDetails(facility)}>
                  <Pencil />
                </Button>
              </div>
              <div>{name}</div>
              <div>{address}</div>
              <div>
                {zipCode} {city}
              </div>
              <div>
                Suppression des sons : {areSoundsDeleted ? 'oui' : 'non'}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col items-start gap-2 lg:items-end">
                <div className="flex flex-row items-center gap-4">
                  Plages horaires
                  <Button onClick={handleEditTimeRanges}>
                    <Pencil />
                  </Button>
                </div>
                <div>Enregistrement {timeRangeString(recordingTimeRange)}</div>
                <div>Analyse {timeRangeString(analysisTimeRange)}</div>
                <div>Surveillance {timeRangeString(monitoringTimeRange)}</div>
                <div className="flex flex-row flex-wrap justify-start gap-2 lg:justify-end">
                  <Button onClick={handleViewContacts}>
                    {contacts?.length} contacts
                  </Button>
                  <Button
                    onClick={handleShowWifis}
                    className="flex flex-row gap-2"
                  >
                    <Wifi /> {Object.keys(wifis ?? []).length}
                  </Button>
                </div>
                <div className="flex flex-row flex-wrap justify-start gap-2 lg:justify-end">
                  <Button
                    variant="secondary"
                    title="Exporter la config Wifi vers les téléphones"
                    onClick={handleSyncPolicy}
                  >
                    Sync policy
                  </Button>
                  <Button
                    variant="secondary"
                    title="Afficher le QR code d'enrôlement de l'établissement"
                    onClick={handleShowQRCode}
                  >
                    QR Code
                  </Button>
                  <Button onClick={handleShowZones}>
                    {plural(Object.keys(zones ?? {}).length, 'zone', false)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <canvas id="canvas" ref={QRCanvasRef} className="hidden" />
          <div className="flex flex-row gap-2">
            {isFacilityTypeCustomerOrExperimenter(facility.type) &&
              hasActiveOrPendingDevices && (
                <div className="flex flex-row gap-2">
                  <GrafanaPanel
                    dashboardId="overview-vm"
                    panelId={2}
                    facility={facilityId}
                  />
                  <GrafanaPanel
                    dashboardId="overview-vm"
                    panelId={3}
                    facility={facilityId}
                  />
                </div>
              )}
            <pre className="flex-1 whitespace-pre-wrap text-gray-500">
              {comment}
            </pre>
          </div>
          <div className="flex flex-col gap-3">
            <div className="bg-popover flex flex-col gap-3 px-4 py-4">
              <Title>
                {filteredSerials.length} / {Object.keys(devices ?? {}).length}{' '}
                appareils
              </Title>
              <div className="flex flex-row items-baseline gap-12">
                <label className="flex flex-row items-baseline gap-2">
                  Connexion
                  <Select
                    onChange={(event) => {
                      setOnlineFilter(event.target.value as OnlineFilterOptions)
                    }}
                    value={onlineFilter}
                  >
                    <option value={ALL}>Tous</option>
                    <option value="offline">Hors-ligne</option>
                    <option value="online">En-ligne</option>
                  </Select>
                </label>
                <label className="flex flex-row items-baseline gap-2">
                  <input
                    type="checkbox"
                    checked={hideOOO}
                    onChange={(event) => setHideOOO(event.target.checked)}
                  />
                  Non HS
                </label>
                <label className="flex flex-row items-baseline gap-2">
                  Version
                  <Select
                    onChange={(event) => setSelectedVersion(event.target.value)}
                    value={selectedVersion}
                  >
                    <option value={ALL}>Toutes</option>
                    {devicesVersions.map((version) => {
                      return (
                        <option key={version} value={version}>
                          v{version}
                        </option>
                      )
                    })}
                  </Select>
                </label>
              </div>
            </div>
            <div className="grid grid-cols-[1em,minmax(0,max-content),8em,2em,6em] gap-x-4 gap-y-1 overflow-y-auto">
              {filteredSerials.map((serial) => {
                // We know serial comes from devices
                const device = devices?.[serial] as DeviceEssential
                return (
                  <React.Fragment key={serial}>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleToggleDevice(serial)}
                    >
                      {selectedSerials.includes(serial) ? (
                        <SquareCheckBig />
                      ) : (
                        <Square />
                      )}
                    </div>
                    <DeviceEssentialItem
                      facilityId={facilityId}
                      device={device}
                      serial={serial}
                    />
                    <div
                      className={`whitespace-nowrap rounded px-3 py-0.5 ${isOnline(serial) ? '' : 'bg-red-900'}`}
                    >
                      {devicesPing[serial] &&
                        DateTime.fromMillis(devicesPing[serial] ?? 0)
                          .setLocale('fr')
                          .toRelative()}
                    </div>
                    <div
                      className={`rounded px-2 py-0.5 ${devicesVersion[serial] === devicesVersions.at(-1) ? '' : 'bg-red-900'}`}
                    >
                      v{devicesVersion[serial] ?? '1'}
                    </div>
                    <div className="flex items-center gap-2">
                      <Button
                        className={`bg-primary text-primary-foreground flex h-5 items-center justify-center rounded`}
                        onClick={() => handlePing(serial)}
                        disabled={pingStatuses[serial] === 'progress'}
                      >
                        Ping
                      </Button>
                      {pingStatuses[serial] === 'progress' ? (
                        <div className="text-destructive-foreground">
                          <Loader />
                        </div>
                      ) : pingStatuses[serial] === 'error' ? (
                        <div className="text-destructive">
                          <AlertTriangle />
                        </div>
                      ) : pingStatuses[serial] === 'success' ? (
                        <div>
                          <Check />
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-2">
          <Button
            title={
              selectedSerials.length > 0
                ? 'Tout déselectionner'
                : 'Sélectionner tout'
            }
            onClick={handleSelectAllOrNoneDevices}
          >
            {selectedSerials.length > 0 ? <Square /> : <SquarePlus />}
          </Button>
          <Button
            className="flex-1"
            onClick={handleBatchEditDevices}
            disabled={!selectedSerials.length}
          >
            Éditer {plural(selectedSerials, 'boîtier', true)}
          </Button>
          <Button
            onClick={handleCopyToClipboard}
            title="Copier la liste dans le presse-papier"
            disabled={!selectedSerials.length}
          >
            <Copy />
          </Button>
          <Button
            className="flex-2"
            disabled={!selectedSerials.length}
            onClick={handleDownloadMacs}
            title="Export adresses MAC en .csv"
          >
            Export MACs
          </Button>
          <Button
            className="flex-2"
            disabled={!selectedSerials.length}
            onClick={handleRegisterVpnClient}
            title="Enregistrer ces ARIs sur le VPN"
          >
            Enregistrer VPN
          </Button>
        </div>
      </div>
    </>
  )
}

export const FacilityInfos: React.FC = () => {
  const { facilityId } = useParams() as { facilityId: string }

  const { facilities } = useContext(DataContext)
  const facility = facilities[facilityId]

  if (!facility) {
    return <Centered>Établissement inconnu</Centered>
  }

  return (
    <FacilityInfos_
      key={facilityId}
      facilityId={facilityId}
      facility={facility}
    />
  )
}
