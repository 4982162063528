import {
  getFacilityTypeIcon,
  isFacilityTypeCustomerOrExperimenter,
} from 'common/facilityType'
import { Pencil } from 'lucide-react'
import React, { useContext, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Deferred } from 'shared/utils/web/deferred'
import { isDeviceRecording } from 'shared/utils/device'
import { DataContext } from '../../DataProvider'
import { editFacility } from '../../api'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { GrafanaPanel } from '../../components/GrafanaPanel'
import { Title } from '../../components/Text'
import { EditCommentDialog } from '../../dialogs/EditCommentDialog'
import { getSortedFacilityIds } from '../../utils/sorters'

export const Overview: React.FC = () => {
  const { facilities, facilityDevices, source } = useContext(DataContext)
  const [editCommentDialogData, setEditCommentDialogData] = useState<{
    deferred: Deferred<void>
    comment: string
    action: (comment: string) => Promise<void>
  } | null>(null)

  const handleEditComment = async (facilityId: string, comment: string) => {
    const deferred = new Deferred<void>()

    setEditCommentDialogData({
      deferred,
      comment,
      action: (
        newComment, // KILLME
      ) =>
        editFacility(
          facilityId,
          facilities[facilityId],
          { comment: newComment },
          source,
          Date.now(),
        ),
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditCommentDialogData(null)
    }
  }

  const sortedFacilityIds = useMemo(
    () => getSortedFacilityIds(facilities),
    [facilities],
  )

  return (
    <>
      {editCommentDialogData && (
        <EditCommentDialog {...editCommentDialogData} />
      )}
      <div className="flex flex-col gap-2 overflow-y-auto">
        <div className="flex flex-col gap-2 px-3 pt-3">
          <Title>Vue d'ensemble</Title>
          <GrafanaPanel dashboardId="overview-vm" panelId={1} />
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto">
          <div className="flex flex-1 flex-col p-3">
            <div className="flex flex-row flex-wrap justify-evenly">
              {sortedFacilityIds
                .filter((facilityId) => {
                  return (
                    isFacilityTypeCustomerOrExperimenter(
                      facilities[facilityId].type,
                    ) &&
                    Object.values(facilityDevices[facilityId] ?? {}).some(
                      (device) => isDeviceRecording(device.status),
                    )
                  )
                })
                .map((facilityId) => {
                  const facility = facilities[facilityId]
                  return (
                    <div
                      className="mb-3 flex flex-col gap-2 p-3"
                      key={facilityId}
                    >
                      <div className="flex gap-2">
                        <NavLink to={`/explorer/${facilityId}`}>
                          <Title>
                            {getFacilityTypeIcon(facility.type)}{' '}
                            {facility.displayName}
                          </Title>
                        </NavLink>
                        <Pencil
                          className="cursor-pointer"
                          onClick={() =>
                            handleEditComment(facilityId, facility.comment)
                          }
                        />
                      </div>
                      <div className="flex gap-2">
                        <GrafanaPanel
                          dashboardId="overview-vm"
                          panelId={2}
                          facility={facilityId}
                        />
                        <GrafanaPanel
                          dashboardId="overview-vm"
                          panelId={3}
                          facility={facilityId}
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
