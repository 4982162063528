import { useForm } from 'react-hook-form'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON, Dialog } from '../components/Dialog'
import { Title } from '../components/Text'
import { Button, SubmitButton } from '../components/ui/button'
import { Textarea } from '../components/ui/textarea'

export const EditCommentDialog = ({
  deferred,
  comment,
  action,
}: {
  deferred: Deferred<void>
  comment: string
  action: (comment: string) => Promise<void> // KILLME
}) => {
  const { handleSubmit, register, formState } = useForm<{
    comment: string
  }>({
    defaultValues: {
      comment,
    },
  })

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog onClose={handleClose} title={<Title>Éditer le commentaire</Title>}>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(async ({ comment }) => {
          await action(comment)
          deferred.resolve()
        })}
      >
        <div className="flex flex-col gap-2">
          <label>Commentaire</label>
          <Textarea {...register('comment')} rows={5} cols={50} />
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button onClick={handleClose}>Annuler</Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
