import { FormProvider, useForm } from 'react-hook-form'
import { Nullable } from 'shared/hooks/createUseMergedFirebase'
import { TimeRanges } from 'shared/types/fleet'
import { TimeRange } from 'shared/types/timeRange'
import { isRangeContainedWithin, timeRangeString } from 'shared/utils/timeRange'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Text, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { TimeRangeEditor } from './TimeRangeEditor'

interface EditableTimeRange extends TimeRange {
  isEditable: boolean
}

interface EditableTimeRanges {
  recordingTimeRange: EditableTimeRange
  analysisTimeRange: EditableTimeRange
  monitoringTimeRange: EditableTimeRange
}

export type FacilityTimeRangesDialogProps = {
  deferred: Deferred<TimeRanges>
  timeRanges: TimeRanges
}

export const FacilityTimeRangesDialog = (
  props: FacilityTimeRangesDialogProps,
) => (
  <TimeRangesDialog {...props} required={true} facilityTimeRanges={undefined} />
)

type Undefinable<T> = {
  [P in keyof T]: T[P] | undefined
}

export type DeviceTimeRangesDialogProps = {
  deferred: Deferred<Nullable<TimeRanges>>
  timeRanges: Undefinable<TimeRanges>
  facilityTimeRanges: TimeRanges
}

export const DeviceTimeRangesDialog = (props: DeviceTimeRangesDialogProps) => (
  <TimeRangesDialog {...props} required={false} />
)

type TimeRangesDialogProps =
  | (FacilityTimeRangesDialogProps & {
      required: true
      facilityTimeRanges: undefined
    })
  | (DeviceTimeRangesDialogProps & {
      required: false
      facilityTimeRanges: TimeRanges
    })

const TimeRangesDialog = ({
  deferred,
  timeRanges,
  required,
  facilityTimeRanges,
}: TimeRangesDialogProps) => {
  function asEditable(field: keyof TimeRanges) {
    const timeRange = required
      ? timeRanges[field]
      : timeRanges[field] ?? facilityTimeRanges[field]
    return { ...timeRange, isEditable: timeRanges[field] !== undefined }
  }

  // Format firebase data to form editable time ranges
  const editableTimeRanges: EditableTimeRanges = {
    recordingTimeRange: asEditable('recordingTimeRange'),
    analysisTimeRange: asEditable('analysisTimeRange'),
    monitoringTimeRange: asEditable('monitoringTimeRange'),
  }

  const context = useForm<EditableTimeRanges>({
    defaultValues: editableTimeRanges,
    mode: 'onBlur',
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = context

  const handleClose = () => {
    if (!isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  function getValue(
    field: keyof TimeRanges,
    editableTimeRanges: EditableTimeRanges,
  ) {
    return required
      ? editableTimeRanges[field]
      : editableTimeRanges[field].isEditable
        ? editableTimeRanges[field]
        : facilityTimeRanges[field]
  }

  register('recordingTimeRange', {
    validate: (recordingTimeRange, formValues) => {
      if (!recordingTimeRange.isEditable) return true

      const analysisTimeRange = getValue('analysisTimeRange', formValues)

      if (!isRangeContainedWithin(analysisTimeRange, recordingTimeRange))
        return "La période d'analyse doit être inclue la période d'enregistrement"

      return true
    },
  })

  register('analysisTimeRange', {
    validate: (analysisTimeRange, formValues) => {
      if (!analysisTimeRange.isEditable) return true

      const recordingTimeRange = getValue('recordingTimeRange', formValues)
      const monitoringTimeRange = getValue('monitoringTimeRange', formValues)

      if (!isRangeContainedWithin(monitoringTimeRange, analysisTimeRange))
        return "La période de surveillance doit être inclue dans celle d'analyse"

      if (!isRangeContainedWithin(analysisTimeRange, recordingTimeRange))
        return "La période d'analyse doit être inclue dans celle d'enregistrement"

      return true
    },
  })

  register('monitoringTimeRange', {
    validate: (monitoringTimeRange, formValues) => {
      if (!monitoringTimeRange.isEditable) return true

      const analysisTimeRange = getValue('analysisTimeRange', formValues)

      if (!isRangeContainedWithin(monitoringTimeRange, analysisTimeRange))
        return "La période de surveillance doit être inclue dans celle d'analyse"

      if (
        facilityTimeRanges &&
        !isRangeContainedWithin(
          monitoringTimeRange,
          facilityTimeRanges.monitoringTimeRange,
        )
      )
        return "La période de surveillance de l'appareil doit être inclue dans celle de l'établissement"
      return true
    },
  })

  function setNonEditableToNull(editableTimeRanges: EditableTimeRanges) {
    return Object.entries(editableTimeRanges).reduce((acc, [key, value]) => {
      if (value.isEditable) {
        delete value.isEditable
        acc[key as keyof TimeRanges] = value
      } else {
        acc[key as keyof TimeRanges] = null
      }
      return acc
    }, {} as Nullable<TimeRanges>)
  }

  function removeIsEditable(editableTimeRanges: EditableTimeRanges) {
    Object.entries(editableTimeRanges).forEach(([, value]) => {
      delete value.isEditable
    })
    return editableTimeRanges
  }

  return (
    <Dialog
      title={<Title>Éditer les plages horaires</Title>}
      onClose={() => deferred.reject(DIALOG_CLOSED_REASON)}
    >
      <FormProvider {...context}>
        <form
          onSubmit={handleSubmit((editableTimeRanges) => {
            required
              ? deferred.resolve(removeIsEditable(editableTimeRanges))
              : deferred.resolve(setNonEditableToNull(editableTimeRanges))
          })}
        >
          <div className="flex flex-col gap-3">
            {facilityTimeRanges && (
              <div className="flex flex-col">
                <Text>Plages horaires établissement :</Text>
                <Text>
                  Enregistrement{' '}
                  {timeRangeString(facilityTimeRanges.recordingTimeRange)}
                </Text>
                <Text>
                  Analyse{' '}
                  {timeRangeString(facilityTimeRanges.analysisTimeRange)}
                </Text>
                <Text>
                  Surveillance{' '}
                  {timeRangeString(facilityTimeRanges.monitoringTimeRange)}
                </Text>
              </div>
            )}

            <div className="flex flex-col">
              <Text>
                Plages horaires{' '}
                {facilityTimeRanges ? 'appareil :' : 'établissement :'}
              </Text>
            </div>
            <TimeRangeEditor
              title="Enregistrement"
              name="recordingTimeRange"
              required={required}
            />
            <TimeRangeEditor
              title="Analyse"
              name="analysisTimeRange"
              required={required}
            />
            <TimeRangeEditor
              title="Surveillance"
              name="monitoringTimeRange"
              required={required}
            />

            <div className="flex items-center justify-end gap-3">
              <Button variant="outline" onClick={handleClose}>
                Annuler
              </Button>
              <SubmitButton isSubmitting={isSubmitting}>Valider</SubmitButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  )
}
