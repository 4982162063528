import { DeviceEssential } from 'common/types'
import { Facilities } from 'shared/types/fleet'
import { Serial } from 'shared/types/utils'
import { collator } from 'shared/utils/web/collator'

export function getSortedDeviceSerials(
  devices: Record<Serial, DeviceEssential>,
) {
  return Object.entries(devices)
    .sort(([_serialA, deviceA], [_serialB, deviceB]) => {
      const { room: roomA, id: idA } = deviceA
      const { room: roomB, id: idB } = deviceB

      return collator.compare(roomA, roomB) || collator.compare(idA, idB)
    })
    .map(([serial, _index]) => serial)
}

export function getSortedFacilityIds(facilities: Facilities) {
  return Object.keys(facilities).sort(
    (facilityIdA: string, facilityIdB: string) => {
      const { type: typeA, displayName: displayNameA } = facilities[facilityIdA]
      const { type: typeB, displayName: displayNameB } = facilities[facilityIdB]

      return (
        typeA.localeCompare(typeB) || displayNameA.localeCompare(displayNameB)
      )
    },
  )
}
