declare global {
  interface Window {
    google: {
      accounts: {
        oauth2: {
          initTokenClient: (InitCodeClient: TokenClientConfig) => TokenClient
        }
      }
    }
  }
}

interface TokenClientConfig {
  client_id: string
  scope: string
  ux_mode: 'popup' | 'redirect'
  callback: (tokenResponse: TokenResponse) => void
  error_callback: (error: unknown) => void
}

export interface TokenResponse {
  access_token: string
  expires_in: number
}

interface TokenClient {
  requestAccessToken: () => void
}

export interface Policy {
  applications: Application[]
  openNetworkConfiguration: {
    NetworkConfigurations: NetworkConfiguration[]
  }
  systemUpdate: SystemUpdate
  mountPhysicalMediaDisabled: boolean
  keyguardDisabled: boolean
  deviceOwnerLockScreenInfo: DeviceOwnerLockScreenInfo
  networkEscapeHatchEnabled: boolean
  skipFirstUseHintsEnabled: boolean
  kioskCustomization: KioskCustomization
}

interface Application {
  packageName: string
  installType: string
  defaultPermissionPolicy: string
  autoUpdateMode: string
}

export interface NetworkConfiguration {
  GUID: string
  Name: string
  Type: 'WiFi'
  WiFi: WiFi
}

export interface WiFi {
  SSID: string
  Security: 'WPA-PSK'
  Passphrase: string
  MACAddressRandomizationMode: 'Hardware'
  AutoConnect: boolean
}

interface SystemUpdate {
  type: 'AUTOMATIC' | 'WINDOWED' | 'POSTPONE'
}

interface DeviceOwnerLockScreenInfo {
  defaultMessage: string
}

interface KioskCustomization {
  powerButtonActions:
    | 'POWER_BUTTON_ACTIONS_UNSPECIFIED'
    | 'POWER_BUTTON_AVAILABLE'
    | 'POWER_BUTTON_BLOCKED'
  systemErrorWarnings:
    | 'SYSTEM_ERROR_WARNINGS_UNSPECIFIED'
    | 'ERROR_AND_WARNINGS_ENABLED'
    | 'ERROR_AND_WARNINGS_MUTED'
  systemNavigation:
    | 'SYSTEM_NAVIGATION_UNSPECIFIED'
    | 'NAVIGATION_ENABLED'
    | 'NAVIGATION_DISABLED'
    | 'HOME_BUTTON_ONLY'
  statusBar:
    | 'STATUS_BAR_UNSPECIFIED'
    | 'NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED'
    | 'NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED'
    | 'SYSTEM_INFO_ONLY'
  deviceSettings:
    | 'DEVICE_SETTINGS_UNSPECIFIED'
    | 'SETTINGS_ACCESS_ALLOWED'
    | 'SETTINGS_ACCESS_BLOCKED'
}

export interface EnrollmentToken {
  duration: string
  policyName: string
  additionalData: string
  oneTimeOnly?: boolean
  allowPersonalUsage:
    | 'ALLOW_PERSONAL_USAGE_UNSPECIFIED'
    | 'PERSONAL_USAGE_ALLOWED'
    | 'PERSONAL_USAGE_DISALLOWED'
}

export type EnrollmentTokenResponse = EnrollmentToken & {
  name: string
  qrCode: string
  value: string
  expirationTimestamp: string
}

interface HardwareInfo {
  brand: string
  hardware: string
  deviceBasebandVersion: string
  manufacturer: string
  serialNumber: string
  model: string
}

interface MemoryInfo {
  totalRam: string
  totalInternalStorage: string
}

export const androidVersions: number[] = []
androidVersions[28] = 9
androidVersions[29] = 10
androidVersions[30] = 11
androidVersions[31] = 12
androidVersions[32] = 12
androidVersions[33] = 13
androidVersions[34] = 14
androidVersions[35] = 15
androidVersions[36] = 16

const NonComplianceReasons = {
  API_LEVEL:
    'The setting is not supported in the API level of the Android version running on the device.',
  MANAGEMENT_MODE:
    "The management mode (profile owner, device owner, etc.) doesn't support the setting.",
  USER_ACTION:
    'The user has not taken required action to comply with the setting.',
  INVALID_VALUE: 'The setting has an invalid value.',
  APP_NOT_INSTALLED:
    'The app required to implement the policy is not installed.',
  UNSUPPORTED:
    'The policy is not supported by the version of Android Device Policy on the device.',
  APP_INSTALLED: 'A blocked app is installed.',
  PENDING:
    "The setting hasn't been applied at the time of the report, but is expected to be applied shortly.",
  APP_INCOMPATIBLE:
    "The setting can't be applied to the app because the app doesn't support it, for example because its target SDK version is not high enough.",
  APP_NOT_UPDATED:
    "The app is installed, but it hasn't been updated to the minimum version code specified by policy.",
  DEVICE_INCOMPATIBLE:
    'The device is incompatible with the policy requirements.',
}

type NonComplianceReason = keyof typeof NonComplianceReasons

const InstallationFailureReasons = {
  INSTALLATION_FAILURE_REASON_UNSPECIFIED: 'This value is disallowed.',
  INSTALLATION_FAILURE_REASON_UNKNOWN:
    "An unknown condition is preventing the app from being installed. Some potential reasons are that the device doesn't have enough storage, the device network connection is unreliable, or the installation is taking longer than expected. The installation will be retried automatically.",
  IN_PROGRESS: 'The installation is still in progress.',
  NOT_FOUND: 'The app was not found in Play.',
  NOT_COMPATIBLE_WITH_DEVICE: 'The app is incompatible with the device.',
  NOT_APPROVED: 'The app has not been approved by the admin.',
  PERMISSIONS_NOT_ACCEPTED:
    'The app has new permissions that have not been accepted by the admin.',
  NOT_AVAILABLE_IN_COUNTRY: "The app is not available in the user's country.",
  NO_LICENSES_REMAINING:
    'There are no licenses available to assign to the user.',
  NOT_ENROLLED:
    'The enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service.',
  USER_INVALID:
    'The user is no longer valid. The user may have been deleted or disabled.',
  NETWORK_ERROR_UNRELIABLE_CONNECTION:
    "A network error on the user's device has prevented the install from succeeding. This usually happens when the device's internet connectivity is degraded, unavailable or there's a network configuration issue. Please ensure the device has access to full internet connectivity on a network that meets Android Enterprise Network Requirements. App install or update will automatically resume once this is the case.",
  INSUFFICIENT_STORAGE:
    "The user's device does not have sufficient storage space to install the app. This can be resolved by clearing up storage space on the device. App install or update will automatically resume once the device has sufficient storage.",
}

type InstallationFailureReason = keyof typeof InstallationFailureReasons

export const SpecificNonComplianceReasons = {
  SPECIFIC_NON_COMPLIANCE_REASON_UNSPECIFIED:
    'Specific non-compliance reason is not specified.',
  PASSWORD_POLICIES_USER_CREDENTIALS_CONFIRMATION_REQUIRED:
    'User needs to confirm credentials by entering the screen lock.',
  PASSWORD_POLICIES_PASSWORD_EXPIRED:
    'The device or profile password has expired.',
  PASSWORD_POLICIES_PASSWORD_NOT_SUFFICIENT:
    'The device password does not satisfy password requirements.',
  ONC_WIFI_INVALID_VALUE:
    'There is an incorrect value in ONC Wi-Fi configuration.',
  ONC_WIFI_API_LEVEL:
    'The ONC Wi-Fi setting is not supported in the API level of the Android version running on the device.',
  ONC_WIFI_INVALID_ENTERPRISE_CONFIG:
    'The enterprise Wi-Fi network is missing either the root CA or domain name.',
  ONC_WIFI_USER_SHOULD_REMOVE_NETWORK:
    'User needs to remove the configured Wi-Fi network manually. This is applicable only on work profiles on personally-owned devices.',
  ONC_WIFI_KEY_PAIR_ALIAS_NOT_CORRESPONDING_TO_EXISTING_KEY:
    'Key pair alias specified via ClientCertKeyPairAlias field in openNetworkConfiguration does not correspond to an existing key installed on the device.',
}

type SpecificNonComplianceReason = keyof typeof SpecificNonComplianceReasons

interface NonComplianceDetails {
  settingName: string
  nonComplianceReason: NonComplianceReason
  packageName?: string
  fieldPath?: string
  installationFailureReason?: InstallationFailureReason
  specificNonComplianceReason?: SpecificNonComplianceReason
  specificNonComplianceContext?: unknown
}

const PowerManagementEventTypes = {
  POWER_MANAGEMENT_EVENT_TYPE_UNSPECIFIED:
    'Unspecified. No events have this type.',
  BATTERY_LEVEL_COLLECTED: 'Battery level was measured.',
  POWER_CONNECTED: 'The device started charging.',
  POWER_DISCONNECTED: 'The device stopped charging.',
  BATTERY_LOW: 'The device entered low-power mode.',
  BATTERY_OKAY: 'The device exited low-power mode.',
  BOOT_COMPLETED: 'The device booted.',
  SHUTDOWN: 'The device shut down.',
}

type PowerManagementEventType = keyof typeof PowerManagementEventTypes

interface PowerManagementEvent {
  eventType: PowerManagementEventType
  createTime: string // ISO date
  batteryLevel?: number
}

export interface Device {
  apiLevel: number
  appliedPolicyVersion: string
  hardwareInfo: HardwareInfo
  enrollmentTokenData: string
  enrollmentTime: string // ISO date
  lastStatusReportTime: string // ISO date
  lastPolicySyncTime: string // ISO date
  memoryInfo: MemoryInfo
  nonComplianceDetails?: NonComplianceDetails[]
  powerManagementEvents?: PowerManagementEvent[]
}
