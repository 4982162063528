import { CalendarCheck2 as CalendarIcon, TriangleAlert } from 'lucide-react'
import { useMemo, useState } from 'react'
import { GetPushKey } from 'shared/types/firebase'
import { Zones } from 'shared/types/fleet'
import { FirebaseKey, Room } from 'shared/types/utils'
import { NO_ROOM_VALUE } from 'shared/utils/device'
import { plural } from 'shared/utils/plural'
import { roomIsInZone, roomsWithinRange, zoneSorter } from 'shared/utils/zone'
import { collator } from 'shared/utils/web/collator'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Hint, Text, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Edited } from '../../utils/computeDiff'
import {
  FormZone,
  FormZones,
  asZone,
  toEdited,
  toFormZones,
} from '../../utils/zones'
import { EMPTY_REGION, ZoneEditor } from './ZoneEditor'
import { ZoneRegion } from './ZoneRegion'

export type ZonesDialogProps = {
  getPushKey: GetPushKey
  deferred: Deferred<Edited<Zones>>
  zones: Zones
  rooms: Room[]
}

export const ZonesDialog = ({
  deferred,
  zones,
  rooms,
  getPushKey,
}: ZonesDialogProps) => {
  const [editedZoneId, setEditedZoneId] = useState<FirebaseKey | null>(null)
  const [formZones, setFormZones] = useState<FormZones>(toFormZones(zones))
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const sortedRooms: Room[] = useMemo(
    () =>
      rooms
        .filter((room) => room !== NO_ROOM_VALUE)
        .sort((roomA, roomB) => collator.compare(roomA, roomB)),
    [rooms],
  )

  // Could be extended to take timeRange and category into account
  const roomsWithNoZone: Room[] = useMemo(
    () =>
      sortedRooms.filter((room) => {
        return !Object.values(formZones).some((formZone) => {
          const zone = asZone(formZone)
          return roomIsInZone(room, zone)
        })
      }),
    [formZones, sortedRooms],
  )

  const appendZone = () => {
    const zoneId = getPushKey()
    const newZone: FormZone = {
      name: '',
      order: 0,
      regions: { [Math.random().toString(36).slice(2)]: EMPTY_REGION },
      isHoliday: false,
      holidayZoneId: '',
    }

    setFormZones({
      ...formZones,
      [zoneId]: newZone,
    })
    setEditedZoneId(zoneId)
  }

  function updateZone(zoneId: FirebaseKey, updatedZone: FormZone) {
    const updatedZones = { ...formZones, [zoneId]: updatedZone }
    setFormZones(updatedZones)
    setEditedZoneId(null)
  }

  const handleClose = () => {
    editedZoneId ? setEditedZoneId(null) : deferred.reject(DIALOG_CLOSED_REASON)
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    deferred.resolve(toEdited(formZones))
  }

  return (
    <Dialog
      title={
        <h1 className="text-2xl font-bold">
          {editedZoneId
            ? `Edition zone: ${zones[editedZoneId]?.name ?? ''}`
            : 'Zones'}
        </h1>
      }
      onClose={() => handleClose()}
    >
      {editedZoneId ? (
        <ZoneEditor
          updateZone={updateZone}
          rooms={sortedRooms}
          zones={formZones}
          zone={formZones[editedZoneId]}
          zoneId={editedZoneId}
          closeEditor={() => setEditedZoneId(null)}
        />
      ) : (
        <div className="flex flex-col gap-4">
          {Object.entries(formZones ?? {})
            .sort((zoneA, zoneB) => {
              return zoneSorter(zoneA, zoneB)
            })
            .map(([zoneId, formZone]) => {
              const zone = asZone(formZone)
              return (
                <div
                  key={zoneId}
                  className="flex flex-col items-baseline justify-between gap-1"
                >
                  <div className="flex w-full flex-row flex-wrap justify-between gap-1">
                    <div className="flex flex-row items-center gap-1">
                      <Title>{zone.name}</Title>
                      {zone.holidayZoneId && (
                        <CalendarIcon strokeWidth={1} size={18} />
                      )}
                    </div>
                    <Button
                      onClick={() => {
                        setEditedZoneId(zoneId)
                      }}
                    >
                      Éditer
                    </Button>
                  </div>
                  <div className="w-full">
                    {Object.entries(zone.regions ?? {}).map(
                      ([regionId, region]) => {
                        if (!region) return

                        const roomsInRange = roomsWithinRange(
                          sortedRooms,
                          region.roomRange,
                        )
                        return (
                          <ZoneRegion
                            key={regionId}
                            region={region}
                            rooms={roomsInRange}
                          />
                        )
                      },
                    )}
                  </div>
                  <hr className="my-4 w-full text-center opacity-25" />
                </div>
              )
            })}

          {roomsWithNoZone.length > 0 && (
            <div>
              <div className="flex flex-row items-baseline gap-1">
                <Text variant="alert">
                  <TriangleAlert />
                </Text>
                <Title>Sans zone</Title>
                <Hint>
                  ({plural(roomsWithNoZone.length, 'chambre', false)})
                </Hint>
              </div>
              <div>
                <Text>chambres {roomsWithNoZone.join(', ')}</Text>
              </div>
            </div>
          )}

          <div className="flex flex-row items-center justify-end gap-3">
            <Button
              variant="outline"
              onClick={() => deferred.reject(DIALOG_CLOSED_REASON)}
            >
              Annuler
            </Button>
            <Button disabled={isSubmitting} onClick={() => appendZone()}>
              Ajouter une Zone
            </Button>
            <SubmitButton
              isSubmitting={isSubmitting}
              onClick={() => handleSubmit()}
            >
              Valider
            </SubmitButton>
          </div>
        </div>
      )}
    </Dialog>
  )
}
