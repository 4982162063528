import { PhoneStatus } from 'common/types'

const phoneStatusDisplay: Record<PhoneStatus, string> = {
  spare: 'En réserve',
  active: 'Actif',
  outOfOrder: 'Hors service',
}

export function getPhoneStatusDisplay(status: PhoneStatus) {
  return phoneStatusDisplay[status]
}

export const PHONE_STATUS: PhoneStatus[] = Object.keys(
  phoneStatusDisplay,
) as PhoneStatus[]
