import { log } from 'common/log'
import {
  DeviceCreation,
  DeviceEdition,
  Order,
  Part,
  PartReplacement,
  Phone,
  PhoneEdition,
} from 'common/types'
import { Device, Facility, PartType, PartsIds } from 'shared/types/fleet'
import { collator } from 'shared/utils/web/collator'
import { isObjectEmpty } from 'shared/utils/defined'
import { get, push, remove, set, update } from './firebaseMethods'
import { Edited, computeDiff } from './utils/computeDiff'
import { patch } from './utils/patch'

export async function addFacility(
  facility: Facility,
  source: string,
  timestamp: number = Date.now(),
) {
  const pushRef = await push('facilities', facility)
  await log('facilities', pushRef.key, facility, source, timestamp, push)
}

export async function editFacility(
  facilityId: string,
  oldFacilityDetails: Facility,
  newFacilityDetails: Edited<Facility>,
  source: string,
  timestamp: number,
) {
  const diff = computeDiff(oldFacilityDetails, newFacilityDetails)

  if (!isObjectEmpty(diff)) {
    await patch(`facilities/${facilityId}`, diff)
    await log('facilities', facilityId, diff, source, timestamp, push)
  }
}

async function nextDeviceId() {
  const recentDevices = await get('recentDevices_')

  const largestId = Object.values(recentDevices)
    .map((device) => device.id)
    .sort((idA, idB) => collator.compare(idA, idB))
    .pop()

  if (largestId === undefined)
    throw new Error('Unable to retrieve largest device id from recent')

  return (parseInt(largestId, 10) + 1).toString(10)
}

export async function addDevice(
  { serial, datetime, partOrderIds, ...newDevice }: DeviceCreation,
  source: string,
) {
  const nextId = await nextDeviceId()

  const partsIds = Object.entries(partOrderIds).reduce<PartsIds>(
    (acc, [type, orderId]) => {
      const partType = type as PartType
      const part: Part = { type: partType, ok: true, comment: '', orderId }

      if (partType === 'CASE') {
        const key = nextId
        set(`parts/${key}`, part)
        acc[partType] = key
        return acc
      } else {
        const ref = push('parts', part)
        acc[partType] = ref.key
        return acc
      }
    },
    {} as PartsIds,
  )

  const device: Device = { ...newDevice, partsIds }

  await set(`devices/${serial}`, device)
  await remove(`pendingDevices/${serial}`)
  await set(`recentDevices_/${serial}`, {
    facilityId: device.facilityId,
    id: nextId,
  })

  await log(
    'devices',
    serial,
    device,
    source,
    new Date(datetime).valueOf(),
    push,
  )

  return nextId
}

export async function editDevice(
  serial: string,
  oldDevice: Device,
  newDevice: Edited<DeviceEdition>,
  source: string,
  timestamp: number,
) {
  const diff = computeDiff(oldDevice, newDevice)

  if (!isObjectEmpty(diff)) {
    await patch(`devices/${serial}`, diff)
    await log('devices', serial, diff, source, timestamp, push)
  }

  return diff
}

export async function repairDevice(
  serial: string,
  oldDevice: Device,
  { comment, orderId, partType }: PartReplacement,
  source: string,
  timestamp: number,
) {
  const part: Part = {
    type: partType,
    ok: true,
    comment: '',
    orderId,
  }

  let partId: string

  if (partType === 'CASE') {
    partId = await nextDeviceId()
    await set(`parts/${partId}`, part)
    await set(`recentDevices_/${serial}`, {
      facilityId: oldDevice.facilityId,
      id: partId,
    })
  } else {
    partId = (await push('parts', part)).key
  }

  await update(`parts/${oldDevice.partsIds[partType]}`, {
    ok: false,
    comment,
  })

  await log(
    'parts',
    oldDevice.partsIds[partType],
    { ok: false, comment, serial },
    source,
    timestamp,
    push,
  )

  await set(`devices/${serial}/partsIds/${partType}`, partId)

  await log(
    'devices',
    serial,
    { comment, partsIds: { [partType]: partId } },
    source,
    timestamp,
    push,
  )
}

export async function addOrder(
  order: Order,
  source: string,
  timestamp: number,
) {
  const orderRef = await push('orders', order)
  await log('orders', orderRef.key, order, source, timestamp, push)
  return orderRef.key
}

export async function editPhone(
  key: string,
  oldPhone: Phone,
  newPhone: Edited<PhoneEdition>,
  source: string,
  timestamp: number,
) {
  const diff = computeDiff(oldPhone, newPhone)

  if (!isObjectEmpty(diff)) {
    await patch(`phones/${key}`, diff)
    await log('phones', key, diff, source, timestamp, push)
  }
}
