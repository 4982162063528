import { useContext, useState } from 'react'
import { Serial } from 'shared/types/utils'
import { ToastAction } from '../components/ui/toast'
import { DataContext } from '../DataProvider'
import { login, pingMinion } from '../utils/salt'
import { toast } from './useToast'

export type PingStatus = 'progress' | 'error' | 'success'
export type PingStatuses = Record<Serial, PingStatus>

interface UsePingResult {
  pingStatuses: PingStatuses
  handlePing: (serial: string) => Promise<void>
}

export const usePing = (): UsePingResult => {
  const { salt } = useContext(DataContext)
  const [pingStatuses, setPingStatuses] = useState<PingStatuses>({})

  const handlePing = async (serial: string) => {
    setPingStatuses((prev) => ({ ...prev, [serial]: 'progress' }))

    try {
      const token = await login(salt)
      const success = await pingMinion(salt, token, serial)

      setPingStatuses((prev) => ({
        ...prev,
        [serial]: success ? 'success' : 'error',
      }))

      if (success) {
        toast({
          title: 'Succès du ping',
          description: serial,
        })
      } else {
        toast({
          variant: 'destructive',
          title: 'Echec du ping',
          description: serial,
          action: (
            <ToastAction altText="Réessayer" onClick={() => handlePing(serial)}>
              Réessayer
            </ToastAction>
          ),
        })
      }
    } catch (error) {
      console.warn('PING ERROR for', serial, error)
      setPingStatuses((prev) => ({
        ...prev,
        [serial]: 'error',
      }))
      toast({
        variant: 'destructive',
        title: `La requête a échoué ${serial}`,
        description: `${error}`,
        action: (
          <ToastAction altText="Réessayer" onClick={() => handlePing(serial)}>
            Réessayer
          </ToastAction>
        ),
      })
    }
  }

  return { pingStatuses, handlePing }
}
