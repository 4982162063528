import { DatabaseSchema } from 'common/databaseSchema'
import { NULLED } from 'common/log'
import { DeviceEdition, Edit } from 'common/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  ChevronRight,
  Clock,
  Network,
  Pencil,
  Terminal,
  Wifi,
  Wrench,
} from 'lucide-react'
import { DateTime } from 'luxon'
import React, { useContext, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { DEFAULT_REGION } from 'shared/firebase/region'
import { MergedType, Nullable } from 'shared/hooks/createUseMergedFirebase'
import { GetPushKey } from 'shared/types/firebase'
import { Device, TimeRanges, Wifis } from 'shared/types/fleet'
import { TimeRange } from 'shared/types/timeRange'
import { Serial } from 'shared/types/utils'
import { getDeviceStatusDisplay, isDeviceRecording } from 'shared/utils/device'
import { plural } from 'shared/utils/plural'
import { roomDisplayName } from 'shared/utils/room'
import { Deferred } from 'shared/utils/web/deferred'
import { DataContext } from '../../DataProvider'
import { editDevice } from '../../api'
import { DeviceListItem } from '../../components/DeviceListItem'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { GrafanaPanel } from '../../components/GrafanaPanel'
import { NetworkInfo } from '../../components/NetworkInfo'
import { Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { EditCommentDialog } from '../../dialogs/EditCommentDialog'
import { app } from '../../firebase'
import { getPushKey, set } from '../../firebaseMethods'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'
import { usePing } from '../../hooks/usePing'
import { Edited } from '../../utils/computeDiff'
import { DevicePieces } from './DevicePieces'
import { EditDeviceDialog, EditDeviceDialogData } from './EditDeviceDialog'
import { RepairDeviceDialog } from './RepairDeviceDialog'
import {
  DeviceTimeRangesDialog,
  DeviceTimeRangesDialogProps,
} from './TimeRangesDialog'
import { WifisDialog } from './WifisDialog'

const PropertyChange: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-row items-baseline gap-2">{children}</div>
}

export function getLastDatetime(timestamps: number[]) {
  return timestamps.length ? DateTime.fromMillis(Math.max(...timestamps)) : null
}

export const DeviceInfos: React.FC = () => {
  const { serial } = useParams() as { serial: string }

  const [editDialogData, setEditDialogData] =
    useState<EditDeviceDialogData | null>(null)

  const [repairDialogData, setRepairDialogData] =
    useState<EditDeviceDialogData | null>(null)

  const [timeRangesDialogData, setTimeRangesDialogData] =
    useState<DeviceTimeRangesDialogProps | null>(null)

  const [editCommentDialogData, setEditCommentDialogData] = useState<{
    deferred: Deferred<void>
    comment: string
    action: (comment: string) => Promise<void> // KILLME
  } | null>(null)

  const [wifisDialogData, setWifisDialogData] = useState<{
    deferred: Deferred<Nullable<Wifis>>
    wifis: Wifis
    getPushKey: GetPushKey
  } | null>(null)
  const { pingStatuses, handlePing } = usePing()

  const navigate = useNavigate()

  const { source, facilities } = useContext(DataContext)

  const refPathsMap = useMemo(
    () => ({
      device: `devices/${serial}` as const,
      version: `devicesVersion/${serial}` as const,
      ping: `devicesPing/${serial}` as const,
      history: `history/devices/${serial}` as const,
    }),
    [serial],
  )

  const { data, loading, error } =
    useMergedFirebase<MergedType<typeof refPathsMap, DatabaseSchema>>(
      refPathsMap,
    )

  const sortedHistoryEntries = useMemo(() => {
    if (loading || error) return []

    return Object.entries(data.history).sort(
      ([, { timestamp: timestampA }], [, { timestamp: timestampB }]) =>
        timestampB - timestampA,
    )
  }, [data, loading, error])

  async function handleEdit(
    device: Device,
    deviceStates: [string, Edit<Device>][],
  ) {
    const deferred = new Deferred<Edited<DeviceEdition>>()

    const timestamps = deviceStates.map(([, { timestamp }]) => timestamp)

    setEditDialogData({
      device,
      deferred,
      serial,
      minDatetime: getLastDatetime(timestamps),
    })

    try {
      const diff = await deferred.promise
      if (diff?.facilityId) {
        navigate(`/explorer/${diff.facilityId}/${serial}`)
      }
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditDialogData(null)
    }
  }

  const handleRepair = async (
    device: Device,
    deviceStates: [string, Edit<Device>][],
  ) => {
    const deferred = new Deferred<Edited<DeviceEdition>>()

    const timestamps = deviceStates.map(([, { timestamp }]) => timestamp)

    setRepairDialogData({
      device,
      deferred,
      minDatetime: getLastDatetime(timestamps),
      serial,
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setRepairDialogData(null)
    }
  }

  if (loading) return <Centered>Chargement...</Centered>
  if (error) return <Centered>Erreur</Centered>
  if (!data.device) return <Centered>Appareil inconnu</Centered>

  const device = data.device

  const {
    facilityId,
    recordingTimeRange,
    analysisTimeRange,
    monitoringTimeRange,
    wifis = {},
  } = device

  const {
    displayName,
    recordingTimeRange: facilityRecordingTimeRange,
    analysisTimeRange: facilityAnalysisTimeRange,
    monitoringTimeRange: facilityMonitoringTimeRange,
  } = facilities[facilityId]

  const facilityTimeRanges: TimeRanges = {
    recordingTimeRange: facilityRecordingTimeRange,
    analysisTimeRange: facilityAnalysisTimeRange,
    monitoringTimeRange: facilityMonitoringTimeRange,
  }

  const handleEditComment = async (key: string, comment = '') => {
    const deferred = new Deferred<void>()
    setEditCommentDialogData({
      deferred,
      comment,
      action: (newComment: string) =>
        set(`history/devices/${serial}/${key}/data/comment`, newComment),
    })
    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditCommentDialogData(null)
    }
  }

  const handleEditTimeRanges = async () => {
    const deferred = new Deferred<Nullable<TimeRanges>>()

    const timeRanges = {
      recordingTimeRange,
      analysisTimeRange,
      monitoringTimeRange,
    }

    setTimeRangesDialogData({ deferred, timeRanges, facilityTimeRanges })

    try {
      const timeRanges = await deferred.promise
      await editDevice(serial, device, timeRanges, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setTimeRangesDialogData(null)
    }
  }

  const handleSSHConnect = async () => {
    const url = `http://ssh.webssh.svc.cluster.local/ssh/host/${device.vpnIpAddress}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const handleRegisterVpnClient = async () => {
    await registerVpnClients([serial])
  }

  const handleShowWifis = async () => {
    const deferred = new Deferred<Nullable<Wifis>>()

    const getDeviceWifiPushKey = () => getPushKey(`devices/${facilityId}/wifis`)

    setWifisDialogData({ deferred, wifis, getPushKey: getDeviceWifiPushKey })

    try {
      const wifis = await deferred.promise
      await editDevice(serial, device, { wifis }, source, Date.now())
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setWifisDialogData(null)
    }
  }

  if (loading) return <div>Chargement</div>
  if (error) return <div>Erreur</div>

  return (
    <div className="flex-1 overflow-auto">
      {editDialogData && <EditDeviceDialog {...editDialogData} />}

      {wifisDialogData && <WifisDialog {...wifisDialogData} />}

      {timeRangesDialogData && (
        <DeviceTimeRangesDialog {...timeRangesDialogData} />
      )}

      {editCommentDialogData && (
        <EditCommentDialog {...editCommentDialogData} />
      )}
      {repairDialogData && <RepairDeviceDialog {...repairDialogData} />}
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-1 flex-row flex-wrap items-center gap-2">
            <Title>
              <NavLink to={`/explorer/${facilityId}`}>{displayName}</NavLink>
            </Title>
            <ChevronRight />
            <DeviceListItem device={device} serial={serial} />
            <span>
              {data.ping &&
                DateTime.fromMillis(data.ping).setLocale('fr').toRelative()}
              &nbsp;v{data.version ?? '1'}
            </span>
          </div>
          <Button onClick={() => handleEdit(device, sortedHistoryEntries)}>
            <Pencil />
          </Button>
        </div>
        <div className="flex flex-row items-end gap-2">
          <Button onClick={() => handleRepair(device, sortedHistoryEntries)}>
            <Wrench />
          </Button>
          <Button onClick={handleShowWifis}>
            <div className="flex flex-row gap-2">
              <Wifi />
              <span>{Object.keys(wifis ?? []).length}</span>
            </div>
          </Button>
          <Button onClick={handleEditTimeRanges}>
            <Clock />
          </Button>
          <Button
            onClick={() => handlePing(serial)}
            disabled={pingStatuses[serial] === 'progress'}
          >
            Ping
          </Button>
          <Button
            onClick={handleRegisterVpnClient}
            title="Enregistrer le ARI sur le VPN"
          >
            <Network />
          </Button>
          <Button
            onClick={handleSSHConnect}
            title="Ouvrir un terminal sur le ARI (Nécessite d'être connecté au VPN)"
          >
            <Terminal />
          </Button>
        </div>
        <div className="flex flex-col gap-6 lg:flex-row lg:justify-between">
          <DevicePieces
            pieceIds={{ ...device.partsIds, COMPUTER: device.orderId }}
          />
          <div className="flex flex-col gap-2">
            <NetworkInfo label="IP ADDRESS" value={device.ipAddress} />
            <NetworkInfo label="VPN IP ADDRESS" value={device.vpnIpAddress} />
            <NetworkInfo label="MAC ADDRESS" value={device.macAddress} />
            <NetworkInfo label="SSID" value={device.ssid} />
            <NetworkInfo
              label="ACCESS POINT MAC ADDRESS"
              value={device.accessPointMacAddress}
            />
          </div>
        </div>
        <div className="flex h-auto flex-col gap-2 lg:h-48 lg:flex-row">
          <GrafanaPanel
            dashboardId="ari-v2"
            panelId={62021}
            facility={displayName}
            serial={serial}
          />
          <GrafanaPanel
            dashboardId="ari-v2"
            panelId={42026}
            facility={displayName}
            serial={serial}
          />
          <GrafanaPanel
            dashboardId="ari-v2"
            panelId={62007}
            facility={displayName}
            serial={serial}
          />
        </div>

        <div className="flex flex-1 flex-col gap-3">
          <Title>Historique</Title>

          <div className="flex flex-1 flex-col gap-6">
            {sortedHistoryEntries.map(([key, { timestamp, data }]) => {
              return (
                <div key={key} className="flex flex-col gap-2">
                  <div className="flex flex-row gap-3 align-baseline">
                    <div>
                      Le{' '}
                      {DateTime.fromMillis(timestamp)
                        .setLocale('fr')
                        .toLocaleString(DateTime.DATETIME_SHORT)}
                    </div>
                    {data.comment && (
                      <pre className="text-gray-500">{data.comment}</pre>
                    )}
                    <Pencil
                      className="cursor-pointer"
                      onClick={() => handleEditComment(key, data.comment)}
                    />
                  </div>
                  <div className="ml-4 flex flex-col gap-2">
                    {data.facilityId !== undefined && (
                      <PropertyChange>
                        <div>Nouvel établissement</div>
                        <NavLink to={`/explorer/${data.facilityId}`}>
                          {facilities[data.facilityId].displayName}
                        </NavLink>
                      </PropertyChange>
                    )}
                    {data.room !== undefined && (
                      <PropertyChange>
                        <div>Nouvelle chambre</div>
                        <div>{roomDisplayName(data.room)}</div>
                      </PropertyChange>
                    )}
                    {data.status !== undefined && (
                      <PropertyChange>
                        <div>Nouveau statut</div>
                        <div
                          className={`${
                            isDeviceRecording(data.status)
                              ? 'text-green-400'
                              : 'text-red-400'
                          }`}
                        >
                          {getDeviceStatusDisplay(data.status)}
                        </div>
                      </PropertyChange>
                    )}
                    {data.partsIds !== undefined && (
                      <PropertyChange>
                        <div>Remplacement de pièce</div>
                        <DevicePieces pieceIds={data.partsIds} />
                      </PropertyChange>
                    )}
                    {data.wifis !== undefined && (
                      <PropertyChange>
                        <div>
                          {data.wifis === (NULLED as unknown as Wifis) ? (
                            'Wifi supprimé'
                          ) : (
                            // TODO: extraire cette partie dans un composant comme <ArrayDiff>
                            <div>
                              <div>Wifis modifiés</div>
                              <pre>{JSON.stringify(data.wifis, null, 2)}</pre>
                            </div>
                          )}
                        </div>
                      </PropertyChange>
                    )}
                    {data.recordingTimeRange !== undefined && (
                      <PropertyChange>
                        <div>
                          {data.recordingTimeRange ===
                          (NULLED as unknown as TimeRange) ? (
                            "Horaires d'enregistrement supprimés"
                          ) : (
                            // TODO: extraire cette partie dans un composant comme <ArrayDiff>
                            <div>
                              <div>Horaires d'enregistrement modifiés</div>
                              <pre>
                                {JSON.stringify(
                                  data.recordingTimeRange,
                                  null,
                                  2,
                                )}
                              </pre>
                            </div>
                          )}
                        </div>
                      </PropertyChange>
                    )}
                    {data.analysisTimeRange !== undefined && (
                      <PropertyChange>
                        <div>
                          {data.analysisTimeRange ===
                          (NULLED as unknown as TimeRange) ? (
                            "Horaires d'analyse supprimés"
                          ) : (
                            <div>
                              <div>Horaires d'analyse modifiés</div>
                              <pre>
                                {JSON.stringify(
                                  data.analysisTimeRange,
                                  null,
                                  2,
                                )}
                              </pre>
                            </div>
                          )}
                        </div>
                      </PropertyChange>
                    )}
                    {data.monitoringTimeRange !== undefined && (
                      <PropertyChange>
                        <div>
                          {data.monitoringTimeRange ===
                          (NULLED as unknown as TimeRange) ? (
                            'Horaires de surveillance supprimés'
                          ) : (
                            <div>
                              <div>Horaires de surveillance modifiés</div>
                              <pre>
                                {JSON.stringify(
                                  data.monitoringTimeRange,
                                  null,
                                  2,
                                )}
                              </pre>
                            </div>
                          )}
                        </div>
                      </PropertyChange>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const functions = getFunctions(app, DEFAULT_REGION)
const registerVpnClient = httpsCallable(functions, 'registerVpnClient')

export async function registerVpnClients(serials: Serial[]) {
  await registerVpnClient({ serials })
    .then(() => {
      alert(
        `🎉 Enregistrement VPN de ${plural(serials, 'boîtier', true)} réussi !`,
      )
    })
    .catch((error) => {
      console.error(error)
      alert(error)
    })
}
