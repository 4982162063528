import { IgnoreDBTyping } from 'shared/firebase/typedMethods'
import { isObjectEmpty } from 'shared/utils/defined'
import { update } from '../firebaseMethods'
import { JSONNullableObject, JSONNullableValue, isObject } from './computeDiff'

type UpdateObject<T> = {
  [key in keyof T]?: T[key] | null
}

export async function patch<T>(path: string, diff: JSONNullableObject<T>) {
  const updateObject: UpdateObject<T> = {}

  for (const [key_, value_] of Object.entries(diff)) {
    const key = key_ as keyof T
    const value = value_ as JSONNullableValue

    if (value === null) updateObject[key] = value
    else if (isObject(value)) await patch(`${path}/${key_}`, value)
    else updateObject[key] = value
  }

  if (!isObjectEmpty(updateObject))
    // Unable to use the typed update method here
    // Since the key is procedurally computed, no databsase type inference
    await update(path, updateObject as IgnoreDBTyping)
}
