import { getFacilityTypeIcon } from 'common/facilityType'
import { DeviceEssential, FacilityDevices, Phone } from 'common/types'
import { ChevronRight, ChevronsDown } from 'lucide-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { NavLink, useMatch } from 'react-router-dom'
import { useDebounce } from 'shared/hooks/useDebounce'
import { Facility } from 'shared/types/fleet'
import { FirebaseKey, Serial } from 'shared/types/utils'
import { isObjectEmpty } from 'shared/utils/defined'
import { getDeviceStatusDisplay } from 'shared/utils/device'
import { Deferred } from 'shared/utils/web/deferred'
import { normalize } from 'shared/utils/web/normalize'
import { DataContext, FacilityPhones } from '../../DataProvider'
import { addFacility } from '../../api'
import { DeviceEssentialItem } from '../../components/DeviceListItem'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { PhoneListItem } from '../../components/PhoneListItem'
import { Button } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { getPhoneStatusDisplay } from '../../utils/phoneStatus'
import {
  getSortedDeviceSerials,
  getSortedFacilityIds,
} from '../../utils/sorters'
import { EditFacilityDialog } from './EditFacilityDialog'

function SearchBox({ setTerms }: { setTerms: (terms: string[]) => void }) {
  const [text, setText] = useState('')

  const debouncedText = useDebounce(text, 400)

  useEffect(() => {
    setTerms(
      debouncedText
        .trim()
        .replace(/\s\s+/g, ' ')
        .split(' ')
        .filter((word) => word.length > 0)
        .map((word) => normalize(word)),
    )
  }, [debouncedText, setTerms])

  return (
    <Input
      type="search"
      placeholder="Rechercher..."
      onChange={(e) => setText(e.target.value)}
      value={text}
    />
  )
}

export const Sidebar: React.FC = () => {
  const [searchTerms, setSearchTerms] = useState<string[]>([])

  const matchPhone = useMatch('/explorer/:facilityId/phone/:key')
  const matchFacilitySerial = useMatch('/explorer/:facilityId/:serial')
  const matchFacility = useMatch('/explorer/:facilityId')

  const selectedFacilityId =
    matchPhone?.params.facilityId ??
    matchFacilitySerial?.params.facilityId ??
    matchFacility?.params.facilityId

  const selectedEntityId =
    matchPhone?.params.key ?? matchFacilitySerial?.params.serial

  const [openedFacilities, setOpenedFacilities] = useState<string[]>(() =>
    selectedFacilityId ? [selectedFacilityId] : [],
  )

  const { source, facilities, facilityDevices, facilityPhones } =
    useContext(DataContext)

  const [addFacilityDialogData, setAddFacilityDialogData] = useState<{
    deferred: Deferred<void>
    action: (facility: Facility) => Promise<void>
  } | null>(null)

  const handleAddFacility = async () => {
    const deferred = new Deferred<void>()

    setAddFacilityDialogData({
      deferred,
      action: (values) => addFacility(values, source), // KILLME
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setAddFacilityDialogData(null)
    }
  }

  const sortedFacilityIds = useMemo(
    () => getSortedFacilityIds(facilities),
    [facilities],
  )

  const serialHaystack = useMemo(() => {
    const serialHaystack: Record<Serial, string> = {}

    for (const [facilityId, devices] of Object.entries(facilityDevices)) {
      const { displayName, name } = facilities[facilityId]

      for (const [serial, device] of Object.entries(devices)) {
        const { room, id, status } = device
        const ok =
          status === 'outOfOrder' || status === 'disabled' ? 'ko hs' : 'ok'
        const statusString = getDeviceStatusDisplay(status)

        serialHaystack[serial] = normalize(
          `${displayName} ${name} ${serial} ch${room} #${id} ${statusString} ${ok}`,
        )
      }
    }

    return serialHaystack
  }, [facilities, facilityDevices])

  const filteredFacilityDevices = useMemo(() => {
    return Object.entries(facilityDevices).reduce<FacilityDevices>(
      (acc, [facilityId, devices]) => {
        const filteredDevices = getSortedDeviceSerials(devices).reduce<
          Record<Serial, DeviceEssential>
        >((devicesAcc, serial) => {
          const haystack = serialHaystack[serial]
          if (searchTerms.every((term) => haystack.includes(term))) {
            devicesAcc[serial] = devices[serial]
          }
          return devicesAcc
        }, {})

        if (!isObjectEmpty(filteredDevices)) {
          acc[facilityId] = filteredDevices
        }

        return acc
      },
      {},
    )
  }, [searchTerms, facilityDevices, serialHaystack])

  const filteredFacilityPhones = useMemo(() => {
    return Object.entries(facilityPhones).reduce<FacilityPhones>(
      (acc, [facilityId, phones]) => {
        const { displayName } = facilities[facilityId]
        const filteredPhones = Object.entries(phones)
          .sort(([_keyA, { id: idA }], [_keyB, { id: idB }]) => idA - idB)
          .reduce<Record<FirebaseKey, Phone>>((phonesAcc, [key, phone]) => {
            const haystack = `phone ${displayName} ${phone.id} ${
              phone.status === 'outOfOrder' ? 'ko hs' : 'ok'
            } ${getPhoneStatusDisplay(phone.status)}`.toLowerCase()
            if (
              searchTerms.every((searchTerm) => haystack.includes(searchTerm))
            ) {
              phonesAcc[key] = phone
            }

            return phonesAcc
          }, {})

        if (!isObjectEmpty(filteredPhones)) {
          acc[facilityId] = filteredPhones
        }
        return acc
      },
      {},
    )
  }, [searchTerms, facilities, facilityPhones])

  return (
    <>
      {addFacilityDialogData && (
        <EditFacilityDialog {...addFacilityDialogData} />
      )}

      <div className="bg-popover flex flex-col gap-3 p-3">
        <div className="flex items-center gap-2">
          <ChevronsDown
            onClick={() => {
              openedFacilities.length
                ? setOpenedFacilities([])
                : setOpenedFacilities(Object.keys(facilities))
            }}
            className={`transform cursor-pointer duration-300 ${openedFacilities.length ? 'rotate-0' : '-rotate-90'}`}
          />
          <SearchBox setTerms={setSearchTerms} />
        </div>

        <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
          {sortedFacilityIds.map((facilityId) => {
            const { type, displayName } = facilities[facilityId]
            const devices = filteredFacilityDevices[facilityId]
            const phones = filteredFacilityPhones[facilityId]
            const hasSearch = searchTerms.length > 0
            const opened = hasSearch || openedFacilities.includes(facilityId)

            if (hasSearch && !devices && !phones) return null

            return (
              <div className="flex flex-col gap-2 px-2" key={facilityId}>
                <div
                  className="flex flex-row items-center gap-2"
                  onClick={() => {
                    openedFacilities.includes(facilityId)
                      ? setOpenedFacilities(
                          openedFacilities.filter(
                            (openedFacilityId) =>
                              openedFacilityId !== facilityId,
                          ),
                        )
                      : setOpenedFacilities([...openedFacilities, facilityId])
                  }}
                >
                  <NavLink
                    to={`/explorer/${facilityId}`}
                    className={`flex flex-1 ${
                      selectedFacilityId === facilityId
                        ? 'bg-secondary text-secondary-foreground'
                        : ''
                    }`}
                  >
                    <ChevronRight
                      className={`transform cursor-pointer duration-300 ${opened ? 'rotate-90' : 'rotate-0'}`}
                    />
                    {getFacilityTypeIcon(type)} {displayName}
                  </NavLink>
                </div>
                {opened && (
                  <div className="flex flex-col gap-1">
                    {Object.entries(phones ?? {}).map(([key, phone]) => (
                      <React.Fragment key={key}>
                        <NavLink to={`/explorer/${facilityId}/phone/${key}`}>
                          <PhoneListItem
                            phone={phone}
                            isSelected={selectedEntityId === key}
                          />
                        </NavLink>
                      </React.Fragment>
                    ))}
                    {Object.entries(devices ?? {}).map(([serial, device]) => (
                      <DeviceEssentialItem
                        key={serial}
                        facilityId={facilityId}
                        device={device}
                        serial={serial}
                        isSelected={selectedEntityId === serial}
                      />
                    ))}
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <Button onClick={handleAddFacility}>Ajouter un établissement</Button>
      </div>
    </>
  )
}
