import React from 'react'
import { cn } from './ui/cn'

export const Select = React.forwardRef<
  HTMLSelectElement,
  React.SelectHTMLAttributes<HTMLSelectElement>
>(({ children, className, ...props }, ref) => (
  <select
    {...props}
    ref={ref}
    className={cn(
      'border-input/60 hover:bg-accent/50 hover:text-accent-foreground focus-visible:border-input rounded-md border bg-transparent px-3 py-2 focus:outline-none disabled:cursor-not-allowed disabled:opacity-30',
      className,
    )}
  >
    {children}
  </select>
))
Select.displayName = 'Select'
