import { DeviceEdition, DeviceEssential } from 'common/types'
import { useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Device, DeviceStatus } from 'shared/types/fleet'
import { Serial } from 'shared/types/utils'
import { Deferred } from 'shared/utils/web/deferred'
import {
  NO_ROOM_VALUE,
  getDeviceStatusDisplay,
  isStatusOOO,
} from 'shared/utils/device'
import { DataContext } from '../../DataProvider'
import { editDevice } from '../../api'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { FacilitySelector } from '../../components/FacilitySelector'
import { Select } from '../../components/Select'
import { Error, Hint, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Textarea } from '../../components/ui/textarea'

export const BatchEditDevicesDialog = ({
  deferred,
  serials,
}: {
  deferred: Deferred<void>
  serials: string[]
}) => {
  const { source, facilities, facilityDevices } = useContext(DataContext)

  const { handleSubmit, register, formState, watch } = useForm<
    Omit<DeviceEdition, 'room'> & { type: 'facility' | 'status' }
  >({
    defaultValues: {
      type: 'facility',
      facilityId: '',
      status: 'spare',
      comment: '',
    },
  })

  const [type, status] = watch(['type', 'status'])

  const devices = useMemo(() => {
    const devices: Record<Serial, DeviceEssential> = {}

    for (const devicesByFacility of Object.values(facilityDevices)) {
      for (const [serial, device] of Object.entries(devicesByFacility)) {
        if (serials.includes(serial)) devices[serial] = device
      }
    }

    return devices
  }, [serials, facilityDevices])

  const allRoomsAreSpecified = useMemo(
    () => serials.every((serial) => devices[serial].room !== NO_ROOM_VALUE),
    [serials, devices],
  )

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  function statusSelectOption(status: DeviceStatus) {
    return <option value={status}>{getDeviceStatusDisplay(status)}</option>
  }

  function getFacilityId(serial: Serial) {
    for (const [facilityId, devices] of Object.entries(facilityDevices)) {
      if (Object.keys(devices).includes(serial)) return facilityId
    }
    return undefined
  }

  return (
    <Dialog
      title={<Title>Édition de {serials.length} appareils</Title>}
      onClose={handleClose}
    >
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(
          async ({ type, facilityId, comment, status }) => {
            if (type === 'facility') {
              await Promise.all(
                serials.map((serial) => {
                  const deviceEssential = devices[serial]
                  const newDevice: Partial<DeviceEdition> = {
                    room: NO_ROOM_VALUE,
                    facilityId,
                    comment,
                    status: isStatusOOO(deviceEssential.status)
                      ? 'outOfOrder' // preserve outOfOrder status
                      : 'spare',
                  }
                  // Hack. oldDevice does not need to be a full Device object.
                  // Only the previous values of the newDevice fields are provided for diff.
                  // Make sure to keep this object properties synced with newDevice
                  const oldDevice = {
                    room: deviceEssential.room,
                    facilityId: getFacilityId(serial),
                    comment: '',
                    status: deviceEssential.status,
                  } as Device
                  return editDevice(
                    serial,
                    oldDevice,
                    newDevice,
                    source,
                    Date.now(),
                  )
                }),
              )
            } else {
              const edition: Partial<DeviceEdition> = { status, comment }
              if (status === 'spare') edition.room = NO_ROOM_VALUE

              await Promise.all(
                serials.map((serial) => {
                  // Same hack as above, also keep synced
                  const oldDevice = {
                    status: devices[serial].status,
                    comment: '',
                    room: devices[serial].room,
                  } as Device
                  return editDevice(
                    serial,
                    oldDevice,
                    edition,
                    source,
                    Date.now(),
                  )
                }),
              )
            }
            deferred.resolve()
          },
        )}
      >
        <div className="flex flex-col gap-2">
          <label>Type d'édition</label>
          <Select {...register('type')}>
            <option value="facility">Établissement</option>
            <option value="status">Statut</option>
          </Select>
        </div>
        {type === 'facility' ? (
          <div className="flex flex-col gap-2">
            <label>Établissement</label>
            <FacilitySelector
              facilities={facilities}
              registration={register('facilityId', {
                required: 'Établissement requis',
              })}
            />
            {formState.errors.facilityId ? (
              <Error>{formState.errors.facilityId.message}</Error>
            ) : (
              <Hint>
                Changer d'établissement passera les appareils en réserve
              </Hint>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <label>Statut</label>
            {allRoomsAreSpecified ? null : (
              <Hint>
                Les chambres doivent toutes être renseignées pour avoir tous les
                statuts disponibles
              </Hint>
            )}
            <Select
              {...register('status', {
                required: 'Statut requis',
              })}
            >
              {allRoomsAreSpecified &&
                (['active', 'pending', 'disabled'] as DeviceStatus[]).map(
                  statusSelectOption,
                )}
              {(['spare', 'outOfOrder'] as DeviceStatus[]).map(
                statusSelectOption,
              )}
            </Select>
            {formState.errors.status ? (
              <Error>{formState.errors.status.message}</Error>
            ) : status === 'spare' ? (
              <Hint>
                Passer les appareils en réserve supprimera l'attribution de
                chambre
              </Hint>
            ) : null}
          </div>
        )}

        <div className="flex flex-col gap-2">
          <label>Commentaire</label>
          <Textarea {...register('comment')} />
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
