import { useForm } from 'react-hook-form'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON, Dialog } from '../../components/Dialog'
import { Error, Title } from '../../components/Text'
import { Button, SubmitButton } from '../../components/ui/button'
import { Input } from '../../components/ui/input'
import { set } from '../../firebaseMethods'

export const EditLabelDialog = ({
  deferred,
  label,
  orderId,
}: {
  deferred: Deferred<string>
  label: string
  orderId: string
}) => {
  const { handleSubmit, register, formState } = useForm<{
    label: string
  }>({
    defaultValues: {
      label,
    },
  })

  const handleClose = () => {
    if (!formState.isSubmitting) {
      deferred.reject(DIALOG_CLOSED_REASON)
    }
  }

  return (
    <Dialog title={<Title>Éditer le libellé</Title>} onClose={handleClose}>
      <form
        onSubmit={handleSubmit(async ({ label }) => {
          await set(`orders/${orderId}/label`, label)
          deferred.resolve(label)
        })}
      >
        <div className="flex flex-col gap-2">
          <label>Libellé</label>
          <Input
            {...register('label', {
              required: 'Libellé requis',
            })}
          />
          {formState.errors.label && (
            <Error>{formState.errors.label.message}</Error>
          )}
        </div>

        <div className="flex items-center justify-end gap-3">
          <Button variant="outline" onClick={handleClose}>
            Annuler
          </Button>
          <SubmitButton isSubmitting={formState.isSubmitting}>
            Valider
          </SubmitButton>
        </div>
      </form>
    </Dialog>
  )
}
