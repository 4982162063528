import { Edit, Phone } from 'common/types'
import { ChevronRight, Pencil } from 'lucide-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { isDefined } from 'shared/utils/defined'
import { fetch_ } from 'shared/utils/fetch'
import { formatIsoDate, formatMsDate } from 'shared/utils/time'
import { Deferred } from 'shared/utils/web/deferred'
import { DIALOG_CLOSED_REASON } from '../../components/Dialog'
import { PhoneListItem } from '../../components/PhoneListItem'
import { Hint, Text, Title } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { DataContext } from '../../DataProvider'
import { EditCommentDialog } from '../../dialogs/EditCommentDialog'
import { set } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'
import alcatel1b from '../../images/alcatel-5031G.png'
import alcatelHY from '../../images/alcatel-HeyYou.png'
import leSwipe from '../../images/logicom-le-swipe.png'
import armorX12 from '../../images/ulefone-ArmorX12.png'
import unknownPhone from '../../images/unknownPhone.png'
import { getDevice, rebootDevice } from '../../utils/mdm'
import {
  Device,
  SpecificNonComplianceReasons,
  androidVersions,
} from '../../utils/mdmTypes'
import { getPhoneStatusDisplay } from '../../utils/phoneStatus'
import { getLastDatetime } from './DeviceInfos'
import { EditPhoneDialog, EditPhoneDialogData } from './EditPhoneDialog'
import { getMdmToken } from './mdm'

export const PhoneInfos: React.FC = () => {
  const { key: firebaseKey } = useParams() as { key: string }

  const [editDialogData, setEditDialogData] =
    useState<EditPhoneDialogData | null>(null)

  const [mdmDevice, setMdmDevice] = useState<Device>()

  const [editCommentDialogData, setEditCommentDialogData] = useState<{
    deferred: Deferred<void>
    comment: string
    action: (comment: string) => Promise<void>
  } | null>(null)

  const { facilities } = useContext(DataContext)

  const entriesState = useFirebase(`history/phones/${firebaseKey}`)

  const { data: phone, loading, error } = useFirebase(`phones/${firebaseKey}`)

  const sortedEntries = useMemo(() => {
    const entries = entriesState.data
    if (!entries) return []

    return Object.entries(entries).sort(
      ([, { timestamp: timestampA }], [, { timestamp: timestampB }]) =>
        timestampB - timestampA,
    )
  }, [entriesState])

  useEffect(() => {
    setMdmDevice(undefined)
  }, [firebaseKey])

  async function handleEdit(
    phone: Phone,
    phoneStates: [string, Edit<Phone>][],
  ) {
    const deferred = new Deferred<void>()

    const timestamps = phoneStates.map(([, { timestamp }]) => timestamp)

    setEditDialogData({
      phone,
      deferred,
      firebaseKey,
      minDatetime: getLastDatetime(timestamps),
    })

    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditDialogData(null)
    }
  }

  if (loading) return <Centered>Chargement...</Centered>
  if (error) return <Centered>Erreur</Centered>
  if (!phone) return <Centered>Téléphone inconnu</Centered>

  const { facilityId, status } = phone
  const { displayName } = facilities[facilityId]

  const handleEditComment = async (key: string, comment = '') => {
    const deferred = new Deferred<void>()
    setEditCommentDialogData({
      deferred,
      comment,
      action: (newComment: string) =>
        set(`history/phones/${firebaseKey}/${key}/data/comment`, newComment),
    })
    try {
      await deferred.promise
    } catch (error) {
      if (error !== DIALOG_CLOSED_REASON) {
        throw error
      }
    } finally {
      setEditCommentDialogData(null)
    }
  }

  async function handleReboot(phone: Phone) {
    const confirmed = window.confirm(
      `Are you sure you want to reboot phone ${phone.id} at ${displayName}?`,
    )
    if (confirmed) {
      if (phone.mdmId) {
        const token = await getMdmToken()
        rebootDevice(token, phone.mdmId)
      } else {
        await fetch_(
          `https://europe-west1-oso-ari.cloudfunctions.net/remotePhoneReboot?facilityId=${phone.facilityId}&userId=${phone.uid}`,
          { mode: 'no-cors' },
        )
      }
    }
  }

  async function retrieveMdmDevice(mdmId: string) {
    const token = await getMdmToken()
    setMdmDevice(await getDevice(token, mdmId))
  }

  return (
    <>
      {editDialogData && <EditPhoneDialog {...editDialogData} />}
      {editCommentDialogData && (
        <EditCommentDialog {...editCommentDialogData} />
      )}
      <div className="flex flex-1 flex-col gap-3 overflow-y-auto p-3">
        <div className="flex flex-row items-center justify-between gap-2">
          <div
            className={`flex flex-1 flex-row flex-wrap gap-2 ${status === 'active' ? '' : 'opacity-50'}`}
          >
            <NavLink to={`/explorer/${facilityId}`}>
              <Title>{displayName}</Title>
            </NavLink>
            <ChevronRight />
            <PhoneListItem phone={phone} />
          </div>
          <Button onClick={() => handleEdit(phone, sortedEntries)}>
            <Pencil />
          </Button>
        </div>

        <div className="flex flex-row justify-between gap-2">
          <div className="flex flex-col items-start gap-1">
            <Text>Modèle : {phone.model}</Text>
            <Text>Numéro : {phone.number}</Text>
            <Hint>Enregistré le {formatMsDate(phone.createdAt)}</Hint>
          </div>
          <div className="flex flex-col gap-2">
            <Button
              variant={phone.mdmId ? 'secondary' : 'default'}
              onClick={() => handleReboot(phone)}
            >
              Reboot
            </Button>
            {phone.mdmId && !mdmDevice && (
              <Button
                variant="secondary"
                onClick={() => retrieveMdmDevice(phone.mdmId!)}
              >
                MDM Infos
              </Button>
            )}
          </div>
        </div>

        {mdmDevice && (
          <div className="flex flex-row gap-8">
            <img
              src={phoneImage(mdmDevice.hardwareInfo.model)}
              alt={mdmDevice.hardwareInfo.brand}
            />
            <div className="flex flex-col">
              <div>
                <b>
                  Modèle {mdmDevice.hardwareInfo.brand}&nbsp;
                  {mdmDevice.hardwareInfo.model}
                </b>
              </div>
              <div>Android {androidVersions[mdmDevice.apiLevel]}</div>
              <div>
                RAM {formatMemory(mdmDevice.memoryInfo.totalRam)},
                stockage&nbsp;
                {formatMemory(mdmDevice.memoryInfo.totalInternalStorage)}
              </div>
              <div>
                Policy {mdmDevice.enrollmentTokenData}, version&nbsp;
                {mdmDevice.appliedPolicyVersion}
              </div>
              <hr className="my-4" />
              <div>Enrollé le {formatIsoDate(mdmDevice.enrollmentTime)}</div>
              <div>
                Dernier statut envoyé le{' '}
                {formatIsoDate(mdmDevice.lastStatusReportTime)}
              </div>
              <div>
                Dernière synchro MDM le{' '}
                {formatIsoDate(mdmDevice.lastPolicySyncTime)}
              </div>
              {mdmDevice.nonComplianceDetails?.length && (
                <div>
                  <hr className="my-4" />
                  <div>
                    {mdmDevice.nonComplianceDetails.length} non conformités :
                  </div>
                  <div>
                    {[
                      ...new Set(
                        mdmDevice.nonComplianceDetails
                          .map((detail) => detail.specificNonComplianceReason)
                          .filter(isDefined),
                      ),
                    ].map((reason) => (
                      <div
                        key={reason}
                        title={SpecificNonComplianceReasons[reason]}
                      >
                        {reason}
                        <span className="mx-2 rounded-sm bg-gray-600 px-2">
                          ?
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-none flex-col items-start gap-3 md:flex-1">
          <Title>Historique</Title>

          <div className="flex flex-1 flex-col gap-3 overflow-y-auto">
            {sortedEntries.map(([key, { timestamp, data }]) => {
              return (
                <div className="flex flex-col gap-2" key={timestamp}>
                  <div className="flex items-baseline gap-1">
                    <Text>Le {formatMsDate(timestamp)}</Text>
                    {data.comment && (
                      <pre className="ml-3">
                        <Hint>{data.comment}</Hint>
                      </pre>
                    )}
                    <Pencil
                      size={16}
                      className="ml-2 cursor-pointer"
                      onClick={() => handleEditComment(key, data.comment)}
                    />
                  </div>
                  <div className="ml-3 flex flex-col gap-2">
                    {data.facilityId !== undefined && (
                      <PropertyChange>
                        <Text>Nouvel établissement</Text>
                        <NavLink to={`/explorer/${data.facilityId}`}>
                          {facilities[data.facilityId].displayName}
                        </NavLink>
                      </PropertyChange>
                    )}
                    {data.model !== undefined && (
                      <PropertyChange>
                        <Text>Nouveau modèle</Text>
                        <Text>{data.model}</Text>
                      </PropertyChange>
                    )}
                    {data.number !== undefined && (
                      <PropertyChange>
                        <Text>Nouveau numéro</Text>
                        <Text>{data.number}</Text>
                      </PropertyChange>
                    )}
                    {data.status !== undefined && (
                      <PropertyChange>
                        <Text>Nouveau statut</Text>
                        <Text
                          variant={
                            data.status === 'active' ? 'success' : 'alert'
                          }
                        >
                          {getPhoneStatusDisplay(data.status)}
                        </Text>
                      </PropertyChange>
                    )}
                    {data.zoneLocked !== undefined && (
                      <PropertyChange>
                        <Text>Zone bloquée</Text>
                        <Text>{data.zoneLocked ? 'Oui' : 'Non'}</Text>
                      </PropertyChange>
                    )}
                    {data.notificationDisabled !== undefined && (
                      <PropertyChange>
                        <Text>Notification désactivée</Text>
                        <Text>{data.notificationDisabled ? 'Oui' : 'Non'}</Text>
                      </PropertyChange>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

function phoneImage(model: string) {
  switch (model) {
    case '5031G':
      return alcatel1b
    case 'HEYOU20PLUS':
      return alcatelHY
    case 'Armor X12':
      return armorX12
    case 'Le Swipe':
      return leSwipe
    default:
      return unknownPhone
  }
}

const PropertyChange: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-row items-baseline gap-2">{children}</div>
}

function formatMemory(memory: string) {
  return `${(Math.round(parseInt(memory, 10) / 1e8) / 10).toFixed(1)} Go`
}
