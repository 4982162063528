import { DatabaseSchema } from 'common/databaseSchema'
import { Order, PieceType } from 'common/types'
import { useContext, useMemo, useState } from 'react'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { PartType } from 'shared/types/fleet'
import { FirebaseKey } from 'shared/types/utils'
import { DataContext } from '../../DataProvider'
import { Hint, Text } from '../../components/Text'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'

type PieceIds = Partial<Record<PieceType, FirebaseKey>>

const PieceOrder = ({
  pieceType,
  order,
}: {
  pieceType: PieceType
  order: Order
}) => {
  return (
    <div className="flex items-baseline gap-2">
      <Text>{pieceType}</Text>
      <Hint>{order.label}</Hint>
    </div>
  )
}

export const DevicePieces = ({ pieceIds }: { pieceIds: PieceIds }) => {
  const [showParts, setShowParts] = useState(false)

  return (
    <div className="flex flex-col gap-2">
      {showParts ? (
        <DevicePiecesLoader pieceIds={pieceIds} />
      ) : (
        Object.keys(pieceIds).map((pieceType) => (
          <div className="flex flex-row items-baseline gap-3" key={pieceType}>
            <Text>{pieceType}</Text>
            <div
              className="h-3 w-24 cursor-pointer rounded-full bg-sky-900"
              onClick={() => setShowParts(true)}
            ></div>
          </div>
        ))
      )}
    </div>
  )
}

const DevicePiecesLoader = ({ pieceIds }: { pieceIds: PieceIds }) => {
  const { orders } = useContext(DataContext)

  const partsPathsMap = useMemo(
    () =>
      Object.entries(pieceIds).reduce<Partial<Record<PartType, string>>>(
        (acc, [pieceType, partId]) => {
          if (pieceType === 'COMPUTER') return acc
          acc[pieceType as PartType] = `parts/${partId}`
          return acc
        },
        {},
      ),
    [pieceIds],
  )

  const { data, loading, error } =
    useMergedFirebase<MergedType<'parts/${string}', DatabaseSchema>>(
      partsPathsMap,
    )

  if (loading) return <div>Chargement...</div>
  if (error) return <div>Erreur</div>

  return (
    <>
      {Object.entries(pieceIds).map(([pieceType, pieceId]) => {
        if (pieceType === 'COMPUTER') {
          return (
            <PieceOrder
              key={pieceType}
              pieceType="COMPUTER"
              order={orders[pieceId]}
            />
          )
        } else
          return (
            <PieceOrder
              key={pieceType}
              pieceType={pieceType as PartType}
              order={orders[data[pieceType].orderId]}
            />
          )
      })}
    </>
  )
}
