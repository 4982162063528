import { Order, OrderUsage, PieceType } from 'common/types'
import { UseFormRegisterReturn } from 'react-hook-form'
import { FirebaseKey } from 'shared/types/utils'
import { Select } from '../../components/Select'

type OrdersStock = Record<
  FirebaseKey,
  {
    label: string
    stock: Record<PieceType, number>
  }
>

export function computeOrdersAvailableStock(
  orders: Record<FirebaseKey, Order>,
  ordersUsage: Partial<Record<FirebaseKey, OrderUsage>>,
) {
  return Object.entries(orders).reduce<OrdersStock>((acc, [orderId, order]) => {
    acc[orderId] = { label: order.label, stock: { ...order.partsCounts } }
    for (const [type, status] of Object.entries(ordersUsage[orderId] ?? {})) {
      acc[orderId].stock[type as PieceType] -=
        (status.ok ?? 0) + (status.ko ?? 0)
    }
    return acc
  }, {})
}

export const OrderSelector = ({
  ordersStock,
  type,
  registration,
}: {
  ordersStock: OrdersStock
  type: PieceType
  registration: UseFormRegisterReturn
}) => {
  return (
    <Select {...registration}>
      {Object.entries(ordersStock)
        .filter(([_orderId, order]) => {
          return order.stock[type] > 0
        })
        .sort(
          ([_orderIdA, { label: labelA }], [_orderIdB, { label: labelB }]) =>
            labelA.localeCompare(labelB),
        )
        .map(([orderId, order]) => (
          <option key={orderId} value={orderId}>
            {order.label} ({order.stock[type]})
          </option>
        ))}
    </Select>
  )
}
