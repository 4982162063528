import { ArrowDown, ArrowUp, Trash2 as Trash } from 'lucide-react'
import { get, useFormContext } from 'react-hook-form'
import { Wifi } from 'shared/types/fleet'
import { Error } from '../../components/Text'
import { Button } from '../../components/ui/button'
import { Input } from '../../components/ui/input'

export type FormWifi = {
  key: string
  wifi: Wifi | null
}

export const WifiEditor = ({
  name,
  wifisLength,
  index,
  append,
  move,
  remove,
}: {
  name: string
  wifisLength: number
  index: number
  move: (currentIndex: number, newIndex: number) => void
  remove: (index?: number | number[]) => void
  append: (obj: FormWifi | FormWifi[]) => void
}) => {
  const {
    register,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext()

  function removeWifi() {
    const key = getValues(`${name}.key`)
    remove(index)
    append({
      key,
      wifi: null,
    })
  }

  const requiredIfNotRemoved = !!watch(`${name}.wifi`)

  return (
    <div className="flex items-center justify-end gap-3">
      <div className="flex flex-col gap-1">
        <Input
          placeholder="SSID"
          {...register(`${name}.wifi.ssid` as const, {
            required: {
              value: requiredIfNotRemoved,
              message: 'SSID requis',
            },
          })}
        />
        {get(errors, `${name}.wifi.ssid`) && (
          <Error>{get(errors, `${name}.wifi.ssid`).message}</Error>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <Input
          placeholder="Mot de passe"
          {...register(`${name}.wifi.password` as const, {
            validate: (password: string) => {
              // Allow empty passwords for open networks
              if (password.length === 0) return true
              return password.length >= 8 || '8 caractères minimum'
            },
          })}
        />
        {get(errors, `${name}.wifi.password`) && (
          <Error>{get(errors, `${name}.wifi.password`).message}</Error>
        )}
      </div>

      <label className="flex items-center gap-2">
        <Input
          type="checkbox"
          {...register(`${name}.wifi.isHidden` as const)}
        />
        Caché
      </label>

      <Button
        variant="outline"
        disabled={index === 0}
        onClick={() => move(index, index - 1)}
      >
        <ArrowUp />
      </Button>
      <Button
        variant="outline"
        disabled={index === wifisLength - 1}
        onClick={() => move(index, index + 1)}
      >
        <ArrowDown />
      </Button>
      <Button onClick={() => removeWifi()} variant="outline">
        <Trash />
      </Button>
    </div>
  )
}
