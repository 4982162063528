import { X } from 'lucide-react'
import React, { MouseEvent, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

export const DIALOG_CLOSED_REASON = 'DIALOG_CLOSED_REASON'

const dialogContainer = document.createElement('div')

document.body.appendChild(dialogContainer)

export const Dialog: React.FC<
  React.PropsWithChildren<{
    title: React.ReactNode
    onClose: () => void
  }>
> = ({ children, title, onClose }) => {
  const backdropRef = useRef<HTMLDivElement>(null)
  const [mouseDownEvent, setMouseDownEvent] = useState<MouseEvent | null>(null)
  return createPortal(
    <div
      className="fixed inset-0 z-20 flex flex-col items-center justify-center bg-black bg-opacity-70"
      ref={backdropRef}
      onMouseDown={(event: MouseEvent) => {
        if (event.target === backdropRef.current) {
          setMouseDownEvent(event)
        }
      }}
      onMouseUp={(event: MouseEvent) => {
        if (event.target === backdropRef.current && mouseDownEvent) {
          onClose()
        }
        setMouseDownEvent(null)
      }}
    >
      <div
        className="bg-popover text-popover-foreground flex min-w-[300px] max-w-[95vw] flex-initial flex-col gap-4 overflow-y-auto rounded-xl p-4 text-base"
        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      >
        <div className="z-30 flex items-center justify-between gap-3">
          {title}
          <X
            className={`cursor-pointer ${title ? '' : 'ml-auto'}`}
            onClick={onClose}
          />
        </div>
        <div className="max-w-full flex-1 gap-3 overflow-y-auto">
          {children}
        </div>
      </div>
    </div>,
    dialogContainer,
  )
}
