import { ExternalLink } from 'lucide-react'
import React, { useContext, useMemo } from 'react'
import { DataContext } from '../DataProvider'

interface GrafanaPanelProps {
  dashboardId: string
  panelId: number
  interval?: number
  facility?: string
  serial?: string
}

export const GrafanaPanel: React.FC<GrafanaPanelProps> = ({
  dashboardId,
  panelId,
  interval = 86400000,
  facility,
  serial,
}) => {
  const { grafana } = useContext(DataContext)

  const url = useMemo(() => {
    const to = Date.now()
    const from = to - interval
    return `${grafana.url}/d-solo/${dashboardId}/overview?orgId=1&refresh=30s&from=${from}&to=${to}&panelId=${panelId}&var-facility=${facility}&var-ari_id=${serial}`
  }, [grafana, dashboardId, panelId, interval, facility, serial])

  return (
    <div className="flex flex-col">
      <iframe title="grafana panel" src={url} className="max-w-full flex-1" />
      <a
        target="_blank"
        rel="noreferer,noopener"
        href={`${grafana.url}/d/${dashboardId}?viewPanel=${panelId}&var-facility=${facility}&var-ari_id=${serial}`}
        className="self-end text-sm opacity-50"
      >
        <div className="flex items-center gap-2">
          Voir sur grafana
          <ExternalLink />
        </div>
      </a>
    </div>
  )
}
