import { Edit } from 'common/types'
import {
  IgnoreDBTyping,
  ThenablePushReference,
} from 'shared/firebase/typedMethods'

type PushFunction = (
  path: string,
  value: IgnoreDBTyping,
) => ThenablePushReference

export async function log<T extends object>(
  entityType: 'devices' | 'facilities' | 'orders' | 'parts' | 'phones',
  entityId: string,
  data: T,
  source: string,
  timestamp: number,
  push: PushFunction,
) {
  const entry: Edit<T> = { source, timestamp, data: tagNulls(data) }

  // No way to enforce that the specified T matches the corresponding entityType
  await push(`history/${entityType}/${entityId}`, entry as IgnoreDBTyping)
}

export const NULLED = '__NULLED__'

// In an object, recursively replace all null values
// with an arbitrary constant value, NULLED.
// Useful to preserve these nulls in database
// so that object history can be traced
export function tagNulls<T extends object>(obj: T) {
  const nulled: Record<string, unknown> = {}

  for (const [key, value] of Object.entries(obj)) {
    if (value === null) nulled[key] = NULLED
    else if (typeof value === 'object') {
      nulled[key] = tagNulls(value)
    } else nulled[key] = value
  }

  // Cheat here and pretend the type is unchanged
  return nulled as T
}
