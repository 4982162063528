import { signOut } from 'firebase/auth'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { auth } from '../firebase'
import { Text } from './Text'
import { Button } from './ui/button'

function headerClassName({ isActive }: { isActive: boolean }) {
  return isActive ? 'pb-2 border-accent border-b-2' : undefined
}

export const Header: React.FC = () => {
  const handleLogout = () => signOut(auth)

  return (
    <div className="bg-popover z-10 flex flex-row flex-wrap items-baseline justify-between gap-2 px-3 py-1 shadow-xl">
      <div className="flex flex-row flex-wrap gap-6 font-bold">
        {import.meta.env.MODE !== 'production' && (
          <Text className="text-lime-300">STAGING</Text>
        )}
        <NavLink className={headerClassName} end to={`/explorer`}>
          Exploreur
        </NavLink>
        <NavLink className={headerClassName} to={`/monitoring`}>
          Surveillance boitiers
        </NavLink>
        <NavLink className={headerClassName} to={`/overview`}>
          Vue d'ensemble
        </NavLink>
        <NavLink className={headerClassName} to={`/orders`}>
          Commandes
        </NavLink>
        <NavLink className={headerClassName} to={`/pending`}>
          Queue de création
        </NavLink>
      </div>
      <Button onClick={handleLogout}>Déconnexion</Button>
    </div>
  )
}
