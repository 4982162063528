import { get, useFormContext } from 'react-hook-form'
import { TimeRanges } from 'shared/types/fleet'
import { Error } from '../../components/Text'
import { Input } from '../../components/ui/input'

export const TimeRangeEditor = ({
  title,
  name,
  required,
}: {
  title: string
  name: keyof TimeRanges
  required: boolean
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const isTimeRangeEditable = watch(`${name}.isEditable`)

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-end justify-between gap-3">
        {!required && (
          <Input
            type="checkbox"
            {...register(`${name}.isEditable`)}
            className="h-7 w-7"
            onChange={(e) => setValue(`${name}.isEditable`, e.target.checked)}
          />
        )}
        <div className="flex flex-1 flex-col gap-1">
          <label>{title} début</label>
          <Input
            type="time"
            {...register(`${name}.start`, {
              required: {
                value: isTimeRangeEditable,
                message: 'Heure de début requise',
              },
            })}
            disabled={!isTimeRangeEditable}
          />
          {get(errors, `${name}.start`) && (
            <Error>{get(errors, `${name}`).message}</Error>
          )}
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <label>{title} fin</label>
          <Input
            type="time"
            {...register(`${name}.end`, {
              required: {
                value: isTimeRangeEditable,
                message: 'Heure de fin requise',
              },
            })}
            disabled={!isTimeRangeEditable}
          />
          {get(errors, `${name}.end`) && (
            <Error>{get(errors, `${name}`).message}</Error>
          )}
        </div>
      </div>
      {get(errors, `${name}`) && (
        <Error>{get(errors, `${name}`).message}</Error>
      )}
    </div>
  )
}
