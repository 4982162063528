import { FacilityDevices } from 'common/types'
import { DeviceStatus } from 'shared/types/fleet'
import { FacilityId, Room, Serial } from 'shared/types/utils'
import { NO_ROOM_VALUE } from 'shared/utils/device'
import { isValidFirebaseKey } from 'shared/utils/firebase'

function checkRoomAndStatus(room: Room, formValues: { status: DeviceStatus }) {
  const { status } = formValues

  switch (status) {
    case 'spare':
      return room === NO_ROOM_VALUE || 'La chambre doit être vide'
    case 'active':
    case 'pending':
    case 'disabled':
      return room !== NO_ROOM_VALUE || 'La chambre doit être renseignée'
    case 'outOfOrder':
      return true
  }
}

export function getFacilityRooms(
  facilityDevices: FacilityDevices,
  facilityId: FacilityId,
  serial: Serial,
) {
  return Object.entries(facilityDevices[facilityId] ?? {})
    .filter(([serial_, _]) => serial_ !== serial)
    .map(([_, device]) => device.room)
    .filter((room) => room !== NO_ROOM_VALUE)
}

export function getRoomValidator(
  facilityDevices: FacilityDevices,
  serial: Serial,
) {
  return {
    roomMatchesStatus: checkRoomAndStatus,
    roomNotInFacility: (room: Room, formValues: { facilityId: FacilityId }) =>
      !getFacilityRooms(
        facilityDevices,
        formValues.facilityId,
        serial,
      ).includes(room) || 'Cette chambre existe déjà dans cet établissement',
    roomIsTrimmed: (room: Room) =>
      room.trim() === room || 'Espace en trop dans le nom de la chambre',
    roomIsValid: (room: Room) =>
      room === NO_ROOM_VALUE ||
      isValidFirebaseKey(room) ||
      'Caractère interdit dans le nom de la chambre',
  }
}
