import { FacilityType } from 'shared/types/fleet'

export function isFacilityTypeCustomerOrExperimenter(
  type: FacilityType,
): type is 'customer' | 'experimenter' {
  return type === 'customer' || type === 'experimenter'
}

const facilityTypeDisplay: Record<FacilityType, string> = {
  customer: 'Client',
  experimenter: 'Expérimentateur',
  workshop: 'Atelier',
  inactive: 'Inactif',
}

export function getFacilityTypeDisplay(type: FacilityType) {
  return facilityTypeDisplay[type]
}

const FACILITY_TYPE_ICON_MAP: Record<FacilityType, string> = {
  customer: '🟢',
  experimenter: '🟡',
  workshop: '⚪️',
  inactive: '🔴',
}

export function getFacilityTypeIcon(type: FacilityType) {
  return FACILITY_TYPE_ICON_MAP[type]
}

export const FACILITY_TYPES = Object.keys(facilityTypeDisplay) as FacilityType[]
