import { SaltInfos } from 'common/types'
import { Serial } from 'shared/types/utils'
import { fetch_ } from 'shared/utils/fetch'

export type PingStatus = 'progress' | 'error' | 'success'
export type PingStatuses = Record<Serial, PingStatus>

export async function login(salt: SaltInfos) {
  return await fetch_(`${salt.url}/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      username: salt.credentials.username,
      password: salt.credentials.password,
      eauth: 'sharedsecret',
    }),
  })
    .then((response) => response.json())
    .then((result) => result.return[0].token)
}

async function acceptKey(salt: SaltInfos, token: string, serial: string) {
  return await fetch_(`${salt.url}`, {
    method: 'POST',
    headers: {
      'X-Auth-Token': token,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      fun: 'key.accept',
      match: `ARI-${serial}`,
      client: 'wheel',
    }),
  })
    .then((response) => response.json())
    .then((result) => result.return[0].data.success)
}

/**
 * Ping a minion using SaltStack API and retrieve the status code.
 * Response times are generally > 5s on a PiZero with a good connection, compared with < 500ms on a minion laptop.
 * @param salt - SaltStack information containing the API URL.
 * @param token - Authentication token for the API.
 * @param serial - Serial number of the minion to ping.
 * @returns The ping result of the specified minion.
 */
export async function pingMinion(
  salt: SaltInfos,
  token: string,
  serial: string,
) {
  return await fetch_(`${salt.url}`, {
    method: 'POST',
    headers: {
      'X-Auth-Token': token,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      fun: 'test.ping',
      tgt: `ARI-${serial}`,
      client: 'local',
    }),
  })
    .then((response) => response.json())
    .then((result) => result.return[0][`ARI-${serial}`])
}

export async function registerSerialToSalt(
  saltInfos: SaltInfos,
  serial: string,
) {
  console.log(new Date().toISOString(), 'Register minion')
  console.log(new Date().toISOString(), 'Login into salt...')
  const token = await login(saltInfos).catch((_) => {
    throw new Error('Échec du login au serveur salt')
  })

  console.log(new Date().toISOString(), 'Ask salt to accep key...')
  await acceptKey(saltInfos, token, serial).catch((_) => {
    throw new Error("La clef du minion n'a pas été acceptée")
  })

  console.log(new Date().toISOString(), 'Wait for 2s...')
  await new Promise((resolve) => setTimeout(resolve, 2000))

  let pingResult
  let i = 3

  do {
    console.log(new Date().toISOString(), `Ping ${i}...`)
    pingResult = await pingMinion(saltInfos, token, serial).catch((error) =>
      console.error(error),
    )
    console.log(new Date().toISOString(), `Ping ${i} result ${pingResult}`)
  } while (!pingResult && i--)

  if (!pingResult) throw new Error('Échec du ping du minion')
}
