import React from 'react'
import { Outlet } from 'react-router-dom'
import { Sidebar } from './Sidebar'

export const Explorer: React.FC = () => {
  return (
    <div className="flex min-h-0 flex-col md:flex-row">
      <Sidebar />
      <Outlet />
    </div>
  )
}
